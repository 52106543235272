/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
    Row,
    Col,
    Label,
    FormGroup,
    Input
} from "reactstrap";

import EditModalCommon from '../EditModalCommon';
import EditModalBase from '../EditModalBase';
import { listData } from "../../../util/ListPageUtil";
import Select from "react-select";


const initEmployee = {
    internalId: "",
    firstName: "",
    lastName: "",
    email: "",
    contractId: "",
    subContractorId: ""
};

class EditSCEmployeeModal extends EditModalCommon {
    constructor(props) {
        super(props, {
            domainObject: "subcontractoremployee",
            subcontractoremployee: initEmployee,
            conSelect: [],
            scSelect: []
        }, initEmployee
        );

        this.checkValid = this.checkValid.bind(this);
    }

    checkValid(employee) {
        console.log("checkValid " + JSON.stringify(employee));
        let { internalId, firstName, lastName, contractId, subContractorId, email } = employee;

        let valid = true;
        if (!internalId || !firstName || !lastName || !contractId || !subContractorId || !email) {
            valid = false;
        }
        return valid;
    }

    async componentDidMount() {
        console.log("ComponentDidMount " + this.state.domainObject);

        const subContractors = await listData("/subcontractors");
        const contracts = await listData("/contracts");

        const conSelect = contracts.map(e => { 
            let obj = {}; 
            obj.label = e.name;
            obj.value = e.id;
            return obj;
        });
        const scSelect = subContractors.map(e => { 
            let obj = {}; 
            obj.label = e.name;
            obj.value = e.id;
            return obj;
        });


        let state = Object.assign({}, this.state);

        state.conSelect = conSelect;
        state.scSelect = scSelect;
        state.initRecord.contractId = conSelect.length === 1 ? conSelect[0].value : "";
        state.initRecord.subContractorId = scSelect.length === 1 ? scSelect[0].value : "";

        this.setState(state);
    }


    render() {
        return (
            <>
                <EditModalBase save={this.save}
                    addHeader={true}
                    valid={this.state.valid}
                    domainObject={this.state.domainObject}
                    modalType="Sub-Contractor Employee"
                    updateTable={this.props.updateTable}
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    newRecord={this.props.newRecord}
                    record={this.props.record}
                    initRecord={this.initEmployee}>
                    <Row>
                        <Label sm={2}>Internal ID</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Input
                                    value={this.state.subcontractoremployee.internalId}
                                    type="required"
                                    onChange={this.valueChange}
                                    name="internalId"
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row>
                    <Row>
                        <Label sm={2}>First Name</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Input
                                    value={this.state.subcontractoremployee.firstName}
                                    type="required"
                                    onChange={this.valueChange}
                                    name="firstName"
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row>
                    <Row>
                        <Label sm={2}>Last Name</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Input
                                    value={this.state.subcontractoremployee.lastName}
                                    type="required"
                                    onChange={this.valueChange}
                                    name="lastName"
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row>
                    <Row>
                        <Label sm={2}>Email</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Input
                                    value={this.state.subcontractoremployee.email}
                                    type="required"
                                    onChange={this.valueChange}
                                    name="email"
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row>
                    <Row>
                        <Label sm={2}>Sub-Contractor</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Select
                                    className="primary react-select"
                                    classNamePrefix="react-select"
                                    placeholder="Please select"
                                    value={this.state.scSelect.find((o) => o.value === this.state.subcontractoremployee.subContractorId)}
                                    options={this.state.scSelect}                        
                                    onChange={(value) => this.valueChangeBase("subContractorId", value.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row>                    
                    <Row>
                        <Label sm={2}>Contract</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Select
                                    className="primary react-select"
                                    classNamePrefix="react-select"
                                    placeholder="Please select"
                                    value={this.state.conSelect.find((o) => o.value === this.state.subcontractoremployee.contractId)}
                                    options={this.state.conSelect}                                    
                                    onChange={(value) => this.valueChangeBase("contractId", value.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row>
                </EditModalBase>
            </>
        );
    }
}

export default EditSCEmployeeModal;