/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Auth, API } from 'aws-amplify';
import config from "../../config";
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import * as Constants from '../../util/Constants.jsx'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import LoadingOverlay from 'react-loading-overlay';
import SweetAlert from "react-bootstrap-sweetalert";





// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Form,
  UncontrolledTooltip,
  CardTitle
} from "reactstrap";

// core components
import PanelHeader from "../../components/PanelHeader/PanelHeader";


class ReportPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fromDate: moment().subtract(6, 'months'),
      toDate: moment(),
      loading: false
    };

  }



  hideAlert() {
    this.setState({
      alert: null
    });
  }



  downloadAppReport = async (event, reportPath, fileName) => {
    event.preventDefault();

    console.log("downloadAppReport");
    let state = Object.assign({}, this.state);
    state.loading = true;
    this.setState(state);

    const user = await Auth.currentAuthenticatedUser();
    let apiName = config.api.API_NAME;
    let path = reportPath;

    let jwtToken = user.signInUserSession.idToken.jwtToken;
    let myInit = { // OPTIONAL
      headers: {
        Authorization: "Bearer " + jwtToken
      }, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      // queryStringParameters: {  // OPTIONAL
      //   fromDate: this.state.fromDate,
      //   toDate: this.state.toDate,
      //   season: localStorage.getItem("selectedSeason")
      // }
    }
    API.get(apiName, path, myInit).then(response => {
      //  console.log("Respone 2233434 : " + JSON.stringify(response));

      const ws = XLSX.utils.json_to_sheet(response);
      this.autofitColumns(response, ws, null);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };      
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: Constants.xlxsFileType });
      FileSaver.saveAs(data, fileName + "_" + moment().format("YYYY_MM_DD_HH_mm") + Constants.xlxsFileExtension);
      this.setState({ loading: false });

    }).catch(error => {
      console.log(error.response ? error.response : error);
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title={"Download Failed"}
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="info"
          >
            {error.response ? error.response : error.message ? error.message : JSON.stringify(error)}
          </SweetAlert>
        ),
        loading: false
      });

    });
  }

  autofitColumns(json, worksheet, header) {

    const jsonKeys = header ? header : Object.keys(json[0]);

    let objectMaxLength = []; 
    for (let i = 0; i < json.length; i++) {
      let value = json[i];
      for (let j = 0; j < jsonKeys.length; j++) {
        if (typeof value[jsonKeys[j]] == "number") {
          objectMaxLength[j] = 10;
        } else {

          const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0;

          objectMaxLength[j] =
            objectMaxLength[j] >= l
              ? objectMaxLength[j]
              : l;
        }
      }

      let key = jsonKeys;
      for (let j = 0; j < key.length; j++) {
        objectMaxLength[j] =
          objectMaxLength[j] >= key[j].length
            ? objectMaxLength[j]
            : key[j].length;
      }
    }

    const wscols = objectMaxLength.map(w => { return { width: w} });

    worksheet["!cols"] = wscols;

  }



  async componentDidMount() {
  }

  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          {this.state.alert}
          <LoadingOverlay
            active={this.state.loading}
            spinner
            text='Loading please wait...'
          >

            <Card className="card-tasks">
              <CardHeader>
                <h5 className="card-category">Report Center</h5>
                <CardTitle tag="h4">Reports</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="3">
                      <FormGroup className={"has-label "}>
                        <label><h5>Employee Certificates</h5></label>
                        <Button
                          className="btn-round btn-icon btn-icon-mini btn-neutral"
                          color="info"
                          id="tooltipAppReport"
                          type="button"
                          onClick={(e) => this.downloadAppReport(e, "/report/empcert", "EmployeeCertificates")}
                        >
                          <i className="now-ui-icons arrows-1_cloud-download-93" />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="tooltipAppReport"
                          trigger={"hover"}
                        >
                          Download Report
                          </UncontrolledTooltip>

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="3">
                      <FormGroup className={"has-label "}>
                        <label><h5>Equipment Certificates</h5></label>
                        <Button
                          className="btn-round btn-icon btn-icon-mini btn-neutral"
                          color="info"
                          id="tooltipVerReport"
                          type="button"
                          onClick={(e) => this.downloadAppReport(e, "/report/equipcert", "EquipmentCertificates")}
                        >
                          <i className="now-ui-icons arrows-1_cloud-download-93" />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="tooltipVerReport"
                          trigger={"hover"}
                        >
                          Download Report
                          </UncontrolledTooltip>

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup className={"has-label "}>
                        <label><h5>Documents</h5></label>
                        <Button
                          className="btn-round btn-icon btn-icon-mini btn-neutral"
                          color="info"
                          id="tooltipEmpReport"
                          type="button"
                          onClick={(e) => this.downloadAppReport(e, "/report/docs", "Documents")}
                        >
                          <i className="now-ui-icons arrows-1_cloud-download-93" />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="tooltipEmpReport"
                          trigger={"hover"}
                        >
                          Download Report
                          </UncontrolledTooltip>

                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup className={"has-label "}>
                        <label><h5>Sub-Contractor Documents</h5></label>
                        <Button
                          className="btn-round btn-icon btn-icon-mini btn-neutral"
                          color="info"
                          id="tooltipEmpReport"
                          type="button"
                          onClick={(e) => this.downloadAppReport(e, "/report/subcdocs", "SubContractorDocuments")}
                        >
                          <i className="now-ui-icons arrows-1_cloud-download-93" />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          trigger={"hover"}
                          target="tooltipEmpReport"
                        >
                          Download Report
                          </UncontrolledTooltip>

                      </FormGroup>
                    </Col>
                  </Row> */}
                </Form>
              </CardBody>

              {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="now-ui-icons loader_refresh spin" /> Updated 3
                    minutes ago
                  </div>
                </CardFooter> */}
            </Card>
          </LoadingOverlay>
        </div>
      </>
    );
  }
}

export default withRouter(ReportPage);
