/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    ButtonGroup,
    Row,
    Col,
    Label,
    Form,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    FormGroup,
    Input
} from "reactstrap";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import moment from "moment";
// react plugin used to create datetimepicker
import ReactTable from "components/ReactTable/ReactTable.js";
import CourseDTModal from "./CourseDTModal"
import { Auth, API } from 'aws-amplify';
import config from "../../config";
import InputMask from 'react-input-mask';
import SweetAlert from "react-bootstrap-sweetalert";


const dataTable = [
    [1, 1601675920797],
    [2, 1601335920797]
];


var statusOptions = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" }
];


var locationOptions = [
    { value: "Dublin", label: "Dublin" },
    { value: "Clare", label: "Clare" },
    { value: "Galway", label: "Galway" },
    { value: "Meath", label: "Meath" },
    { value: "Wicklow", label: "Wicklow" }
];

const initCourse = {
    title: "",
    noParticipants: "",
    description: "",
    dateTime: [],
    cost: 0
};

class InviteCourseModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            emailValid: false,
            data: [],
            invitees: [],
            alert: null
        };

        this.send = this.send.bind(this);
        this.valueEmailChange = this.valueEmailChange.bind(this);
    }


    hideAlert() {
        this.setState({
            alert: null
        });
    }

    alertUser(alertType, message, title, okMethod) {

        this.setState({
            alert: (
                <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={title}
                    onConfirm={() => {
                        this.hideAlert();
                        if (okMethod) {
                            okMethod();
                        }
                    }}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                    confirmBtnText="Ok"
                >
                    {message}
                </SweetAlert>
            )
        });
    }

    async send(e) {
        console.log("Save Clicked");
        const user = await Auth.currentAuthenticatedUser();
        const jwtToken = user.signInUserSession.idToken.jwtToken;

        console.log("Course id " + this.state.course.id);

        let apiName = config.api.API_NAME;
        let path = '/invites';
        let myInit = { // OPTIONAL
            headers: {
                Authorization: "Bearer " + jwtToken
            }, // OPTIONAL
            response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            body: {
                courseId: this.state.course.id,
                courseTitle: this.state.course.title,
                invitees: this.state.invitees
            }
        }
        console.log("myInit = " + JSON.stringify(myInit));
        API.post(apiName, path, myInit).then(response => {
            console.log("Respone : " + JSON.stringify(response));
            this.alertUser('success', this.state.data.length + ' Invites sent successfully', 'Success', this.props.toggle);


        }).catch(error => {
            // console.log(JSON.stringify(response));
            console.log(JSON.stringify(error.response));
            this.alertUser('error', error.response.data.error, 'Error sending invites');
        });

    }


    valueEmailChange(e) {
        var emailRex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let value = e.target.value;
        if (emailRex.test(value) && !this.state.invitees.includes(value)) {
            this.setState({
                emailValid: true,
                email: value,
            });
        } else {
            this.setState({
                emailValid: false,
                email: value
            });

        }
    }


    createTableRow(prop, key) {
        return {
            id: key,
            email: prop,
            actions: (
                // we've added some custom button actions
                <div className="actions-right">
                    {/* use this button to remove the data row */}
                    <Button
                        onClick={() => {
                            let data = Object.assign([], this.state.data);
                            let invitees = Object.assign([], this.state.invitees);
                            data = data.filter((o) => o.id !== key);
                            invitees = invitees.filter((o) => o !== prop);
                            this.setState({
                                data: data,
                                invitees: invitees
                            });
                        }}
                        className="btn-icon btn-round"
                        color="danger"
                        size="sm"
                    >
                        <i className="fa fa-times" />
                    </Button>{" "}
                </div>
            ),
        };
    }


    // async componentDidMount() {
    //     console.log("Invite ComponentDidMount " + JSON.stringify(this.props.course));
    // }

    componentDidUpdate(prevProps) {
        const { course } = this.props;
        // console.log("ComponentDidUpdate " + JSON.stringify(course));
        // if (this.props.app && this.props.app.application && this.props.app.application.id !== (prevProps.app ? prevProps.app.application ? prevProps.app.application.id : null : null)) {
        if ((course && !prevProps.course) || (course && prevProps.course && prevProps.course.id !== course.id)) {
            this.setState({
                course: course,
                data: [],
                invitees: []
            });
        } else if (course !== prevProps.course) {
            this.setState({
                course: initCourse,
                data: [],
                invitees: []
            });
        }
    }


    render() {
        return (
            <>
                <CourseDTModal isOpen={this.state.courseDTModal} toggle={this.toggleCourseDTModal} course={this.state.course} addSchedule={this.addSchedule} />
                <Modal style={{ maxWidth: '1000px', width: '95%', margin: '10px auto' }}
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}

                >
                    <ModalHeader
                        className="justify-content-center uppercase title"
                        toggle={this.props.toggle}
                        tag="h4"
                    >
                        Invite Participants
                    </ModalHeader>
                    <ModalBody>
                        <div className="content">
                            {this.state.alert}
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form className="form-horizontal">
                                        <Card>
                                            {/* <CardHeader>
                                                <CardTitle tag="h4"></CardTitle>
                                            </CardHeader> */}
                                            <CardBody>
                                                <Row>
                                                    <Label sm={2}>Participant Email</Label>
                                                    <Col xs={12} sm={7}>
                                                        <FormGroup>
                                                            <Input
                                                                value={this.state.email}
                                                                type="required"
                                                                onChange={this.valueEmailChange}
                                                                name="email"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Label sm={3} className="label-on-right">
                                                        <Button
                                                            disabled={!this.state.emailValid}
                                                            onClick={() => {
                                                                let data = Object.assign([], this.state.data);
                                                                var invitees = Object.assign([], this.state.invitees);
                                                                data.push(this.createTableRow(this.state.email, data.length + 1));
                                                                invitees.push(this.state.email);
                                                                this.setState({
                                                                    data: data,
                                                                    email: "",
                                                                    invitees: invitees,
                                                                    emailValid: false
                                                                });
                                                            }}
                                                            className="btn"
                                                            color="success"
                                                            size="sm"
                                                        >
                                                            Add
                                                        </Button>
                                                    </Label>
                                                </Row>
                                                <Row>
                                                    <Label sm={2}></Label>
                                                    <Col xs={10} sm={10}>
                                                        <ReactTable
                                                            showPagination={false}
                                                            showFilter={false}
                                                            data={this.state.data}

                                                            columns={[
                                                                {
                                                                    Header: "Participant Email",
                                                                    accessor: "email",
                                                                    sortable: false,
                                                                    filterable: false,
                                                                },
                                                                {
                                                                    Header: "Actions",
                                                                    accessor: "actions",
                                                                    sortable: false,
                                                                    filterable: false,
                                                                }
                                                            ]}
                                                        />
                                                    </Col>


                                                </Row>
                                            </CardBody>
                                            <CardFooter className="text-left">


                                            </CardFooter>
                                        </Card>
                                    </Form>
                                </Col>
                            </Row>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success"
                            disabled={this.state.data.length === 0}
                            onClick={this.send} >Send Invites</Button>
                        <Button
                            color="danger"
                            onClick={this.props.toggle}
                        >
                            Close
                          </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default InviteCourseModal;
