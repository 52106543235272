/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import {
    Button,
} from "reactstrap";
import moment from "moment";
import EditSCEquipmentModal from "./EditSCEquipmentModal.js";
import ListCommon from "../ListCommon";


// core components
import ListBase from "../ListBase";
import ListCertificatesModal from "../ListCertificatesModal.js";

const tableColumns = [
    {
        Header: "Internal ID",
        accessor: "internalId",
    },
    {
        Header: "Name",
        accessor: "name",
    },
    {
        Header: "Created Date",
        accessor: "dateCreated",
    },
    {
        Header: "Actions",
        accessor: "actions",
        sortable: false,
        filterable: false,
    },
];

class ListSCEquipment extends ListCommon {
    constructor(props) {
        super(props, { domainObject : "subcontractorequipment", listCertModal: false });
    }

    toggleListCertModal = () => {
        console.log("toggleListCertModal");
        this.setState({
            listCertModal: !this.state.listCertModal
        });
    };

    setSelectedEquipment = (equipmentId) => {
        console.log("setSelectedEquipment equipmentId : "+JSON.stringify(equipmentId));
        this.setState({
            listCertModal: !this.state.listCertModal,
            selectedEquipmentId: equipmentId
        });
    };


    createTableRow(prop, key) {
        console.log("prop._ct == " + prop._ct);
        return {
            id: prop.id,
            name: prop.name,
            internalId: prop.internalId,
            status: prop.status && prop.status.label ? prop.status.label : prop.status,
            dateTime: moment(parseInt(prop.expiryDate)).format("DD MMM YYYY"),
            dateCreated: moment(parseInt(prop._ct * 1000)).format("DD MMM YYYY"),
            actions: (
                // we've added some custom button actions
                <div className="actions-right">
                    {/* use this button to add a like kind of action */}
                    <Button
                        onClick={() => {
                            // let obj = this.state.data.find((o) => o.id === key);
                            // console.log("This is the certificate to edit : " + JSON.stringify(obj));
                            this.setSelectedEquipment(prop.id);
                        }}
                        className="btn-icon btn-round"
                        color="warning"
                        size="sm"
                    >
                        <i className="fa fa-camera-retro" />
                    </Button>{" "}
                    <Button
                        onClick={() => {
                            // let obj = this.state.data.find((o) => o.id === key);
                            // console.log("This is the certificate to edit : " + JSON.stringify(obj));
                            this.toggleEditModal(false, prop);
                        }}
                        className="btn-icon btn-round"
                        color="warning"
                        size="sm"
                    >
                        <i className="fa fa-edit" />
                    </Button>{" "}
                    {/* use this button to remove the data row */}
                    <Button
                        disabled={false}
                        onClick={() => this.deleteRecord(prop.sk, prop.id)}
                        className="btn-icon btn-round"
                        color="danger"
                        size="sm"
                    >
                        <i className="fa fa-times" />
                    </Button>{" "}
                </div>
            ),
        };
    }


    render() {
        return (
            <>
                {this.state.alert}
                <ListCertificatesModal isOpen={this.state.listCertModal} toggle={this.toggleListCertModal} equipmentId={this.state.selectedEquipmentId} />
                <EditSCEquipmentModal updateTable={this.updateTable} isOpen={this.state.editModal} toggle={this.toggleEditModal} newRecord={this.state.newRecord} record={this.state.record} />
                <ListBase title="Sub-Contractor Equipment"
                    tableColumns={tableColumns} 
                    createTableRow={this.createTableRow}
                    data={this.state.data}
                    toggleEditModal={this.toggleEditModal}
                >

                </ListBase>
            </>
        );
    }
}

export default ListSCEquipment;
