import uuid from "uuid";
import { Storage } from 'aws-amplify';


    /**
     * Saves a file to s3
     * 
     */
    export function saveFile(file, index, id, entityType, setState, parentThis, label, documents, setDocumentState) {
        console.log("Save File");
        const customPrefix = {
            public: id+'/'
            // protected: 'myProtectedPrefix/',
            // private: 'myPrivatePrefix/'
        };

        let fileName = uuid.v4();
        Storage.put(fileName, file, {
            level: 'public',   //default
            customPrefix: customPrefix,
            contentDisposition: "attachment; filename=" + file.name.replace(/[^\x00-\x7F]/g, "_") + "",
            progressCallback(progress) {
                let progressValue = Math.round(progress.loaded / progress.total * 100);
                let state = Object.assign({}, parentThis.state);
                documents[index] ? documents[index].progress = progressValue : documents[index] = { progress: progressValue};       
                // setState(state);
                setDocumentState(documents);
            }
        } )
        .then (result => {
            console.log("Save File Result : "+JSON.stringify(result)) 
            let state = Object.assign({}, parentThis.state);
            documents[index] ? documents[index].fileName = file.name : documents[index] = { fileName: file.name};
            documents[index].s3FileName = fileName;
            documents[index].label = label;
            setDocumentState(documents);
        })
        .catch(err => {
            console.log(err)
        })
    }
    
    /**
     * Performs the click on the input
     * 
     * @param {*} index 
     * @param {*} docs 
     */
    export function handleFileInput(e, index, docs) {
        docs[index].ref.current.click(e);
    };


    /**
     * Handle the download of files
     * 
     * @param {*} event 
     * @param {*} index 
     */
    export function handleDownload(event, index, id, entityType, parentThis) {
        event.preventDefault();
        //console.log("Download File "+JSON.stringify(this.state.documents[index]));
        const customPrefix = {
          public: id+'/'
          // protected: 'myProtectedPrefix/',
          // private: 'myPrivatePrefix/'
        };
        Storage.get(parentThis.state[entityType].documents[index].s3FileName, 
            {
              expires: 300,
              customPrefix: customPrefix
            })
          .then(result => {
              console.log(result)
              //let blob=new Blob([data.Body], {type: data.ContentType});
              let link=document.createElement('a');
              link.href=result;
              link.download=result;
              link.click();
          
          
          })
          .catch(err => console.log(err));
      }


      export function handleDownloadByFullPath(event, path) {        
        event.preventDefault();
        console.log("handleDownloadByFullPath : "+path);
        const customPrefix = {
            public: ''            
          };
        Storage.get(path, 
            {
              expires: 300,
              customPrefix
            })
          .then(result => {
              let link=document.createElement('a');
              link.href=result;
              link.download=result;
              link.click();
          })
          .catch(err => console.log(err));
      }


    export default saveFile;

