/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
    Row,
    Col,
    Label,
    FormGroup,
    Input
} from "reactstrap";

import EditModalCommon from './EditModalCommon';
import EditModalBase from './EditModalBase';
import Datetime from "react-datetime";
import DocUploader from "components/DocUploader";

const docs = [
    {
      label: "Document",
      placeHolder: "Click here to upload document",
      ref: React.createRef(),
      required: true
    }
];

const initDocument = {
    name: "",
    expiryDate: "",
    documents: []
};

class EditDocumentModal extends EditModalCommon {
    constructor(props) {
        super(props, {
            domainObject: "document",
            document: initDocument
        }, initDocument
        );

        this.checkValid = this.checkValid.bind(this);
    }

    checkValid(document) {
        console.log("checkValid " + JSON.stringify(document));
        let { name, documents, expiryDate } = document;

        let valid = true;
        if (!name || !documents || documents.length === 0) {
            valid = false;
        }
        return valid;
    }


    render() {
        return (
            <>
                <EditModalBase save={this.save}
                    addHeader={true}
                    valid={this.state.valid}
                    domainObject="document"
                    modalType="Document"
                    updateTable={this.props.updateTable}
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    newRecord={this.props.newRecord}
                    record={this.props.record}
                    initRecord={this.initDocument}>
                    <Row>
                        <Label sm={2}>Name</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Input
                                    value={this.state.document.name}
                                    type="required"
                                    onChange={this.valueChange}
                                    name="name"
                                />
                            </FormGroup>
                        </Col>

                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row>
                    <Row>
                        <Label sm={2}>Expiry Date</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Datetime
                                    value={this.state.document.expiryDate}
                                    utc={true}
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    inputProps={{ placeholder: "Select Expiry Date" }}
                                    onChange={value => this.handleDateChange(value, "expiryDate")}
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            {/* <code>*</code> */}
                        </Label>

                    </Row>
                    <DocUploader
                        docs={docs}
                        documents={this.state.document.documents}
                        docArrayName="documents"
                        user={this.props.user}
                        disabled={this.props.disabled}
                        id={this.props.user.attributes["custom:tenant_id"]}
                        setDocumentState={this.setDocumentState}
                      />
                    <div id="modal-container"></div>

                </EditModalBase>
            </>
        );
    }
}

export default EditDocumentModal;