/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Row,
    Col,
    Label,
    Form,
    Card,
    CardBody,
    FormGroup,
    Input
} from "reactstrap";

// react plugin used to create DropdownMenu for selecting items
import moment from "moment";
// react plugin used to create datetimepicker
import { Auth, API, Storage } from 'aws-amplify';
import config from "../../config";
import ImageUploader from "react-images-upload";
import Datetime from "react-datetime";
import uuid from "uuid";
import ReactTable from "components/ReactTable/ReactTable.js";
import Viewer from 'react-viewer';
import DocUploader from "components/DocUploader";
import EditCertificate from "./EditCertificate";

const docs = [
    {
        label: "Document",
        placeHolder: "Click here to upload document",
        ref: React.createRef(),
        required: true
    }
];


const initCertificate = {
    name: "",
    expiryDate: null,
    uploads: []
};

class EditCertificateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // saving: false,
            // certificate: initCertificate,
            // data: [],
            // images: [{ src: '', alt: '' }],
            // pictures: [],
            // valid: false,
            // register: {
            //     emailState: "",
            //     passwordState: "",
            //     confirmState: "",
            //     fullNameState: "",
            //     email: "",
            //     password: "",
            //     confirm: "",
            //     fullName: "",
            // },
            // type: {
            //     required: "",
            //     email: "",
            //     number: "",
            //     url: "",
            //     source: "",
            //     destionation: "",
            //     requiredState: "",
            //     emailState: "",
            //     numberState: "",
            //     urlState: "",
            //     equalState: "",
            // }
        };

        // this.valueChange = this.valueChange.bind(this);
        // this.save = this.save.bind(this);
        // this.onDrop = this.onDrop.bind(this);
        // this.handleDateChange = this.handleDateChange.bind(this);
        // this.setDocumentState = this.setDocumentState.bind(this);
    }

    // async save(e) {
    //     console.log("Save Clicked equipId " + this.props.equipmentId);
    //     this.setState({ saving: true })
    //     const user = await Auth.currentAuthenticatedUser();
    //     const jwtToken = user.signInUserSession.idToken.jwtToken;
    //     // let tenant = user.attributes["custom:tenant_id"];

    //     // let uploads = [];

    //     // let pic = this.state.pictures;
    //     // for (let i = 0; i < pic.length; i++) {
    //     //     let s3FileName = await this.saveFile(pic[i], tenant);
    //     //     console.log("s3FileName = " + s3FileName);
    //     //     let upload = {
    //     //         fileName: pic[i].name,
    //     //         s3FileName: s3FileName
    //     //     }
    //     //     uploads.push(upload);
    //     // }

    //     let certificate = this.state.certificate;
    //     // certificate.uploads = uploads;


    //     certificate.bookingId = this.props.booking ? this.props.booking.id : null;
    //     certificate.courseId = this.props.booking ? this.props.booking.courseSk : null;

    //     if (this.props.employeeId) {
    //         certificate.employeeId = this.props.employeeId;
    //         certificate.certType = "EMPLOYEE";
    //     }

    //     if (this.props.equipmentId) {
    //         certificate.equipmentId = this.props.equipmentId;
    //         certificate.certType = "EQUIPMENT";
    //     }

    //     let apiName = config.api.API_NAME;
    //     let path = '/certificates';
    //     let myInit = { // OPTIONAL
    //         headers: {
    //             Authorization: "Bearer " + jwtToken
    //         }, // OPTIONAL
    //         response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    //         body: certificate
    //     }
    //     // console.log("myInit = " + JSON.stringify(myInit));
    //     await API.post(apiName, path, myInit).then(response => {
    //         console.log("Respone : " + JSON.stringify(response));
    //         if (!this.props.booking) {
    //             this.props.updateTable(response);
    //         }
    //         this.props.toggle();

    //     }).catch(error => {
    //         console.log(error);
    //     });


    //     this.setState({ saving: false });

    // }

    // typeRequired(e) {
    //     var type = this.state.type;
    //     type["required"] = e.target.value;
    //     if (type["required"].length > 0) {
    //         type["requiredState"] = "has-success";
    //     } else {
    //         type["requiredState"] = "has-danger";
    //     }
    //     this.setState({ type });
    //     // console.log(JSON.stringify(type));
    // }


    // valueChange(e) {
    //     let certificate = Object.assign({}, this.state.certificate);
    //     certificate[e.target.name] = e.target.value;
    //     this.setState({
    //         certificate: certificate
    //     }, this.checkValid(certificate));
    // }


    // checkValid(certificate) {
    //     let { name, expiryDate } = certificate;

    //     let valid = true;
    //     if (!name || !expiryDate) {
    //         valid = false;
    //     }
    //     this.setState({ valid: valid });
    // }




    // componentDidUpdate(prevProps) {
    //     console.log("this.props.equipmentId == " + this.props.equipmentId);
    //     const { certificate, newCertificate } = this.props;
    //     if ((certificate && !prevProps.certificate) || (certificate && prevProps.certificate && prevProps.certificate.id !== certificate.id) || (certificate && prevProps.certificate && !prevProps.certificate.id && certificate.id)) {
    //         this.setState({
    //             certificate: this.props.newCertificate === false ? certificate : initCertificate,
    //             data: this.props.newCertificate === false && certificate.uploads ? certificate.uploads.map((obj) => this.createTableRow(obj)) : []
    //         });
    //     } else if (newCertificate === true && prevProps.newCertificate !== newCertificate) {
    //         this.setState({
    //             certificate: initCertificate,
    //             data: []
    //         });
    //     }
    // }

    // async saveFile(file, tenant) {
    //     console.log("Save File");

    //     let s3Folder = tenant;

    //     if (this.props.booking) {
    //         s3Folder = this.props.booking.associatedTenants.filter((t) => t.tenantType === 'STUDENT')[0].tenantId;
    //     }

    //     console.log("S3folder = " + s3Folder);
    //     const customPrefix = {
    //         public: s3Folder + '/'
    //         // protected: 'myProtectedPrefix/',
    //         // private: 'myPrivatePrefix/'
    //     };

    //     let fileName = uuid.v4();
    //     let result = await Storage.put(fileName, file, {
    //         level: 'public',   //default
    //         customPrefix: customPrefix,
    //         contentDisposition: "attachment; filename=" + file.name.replace(/[^\x00-\x7F]/g, "_") + "",
    //         // progressCallback(progress) {
    //         //     let progressValue = Math.round(progress.loaded / progress.total * 100);
    //         //     let state = Object.assign({}, parentThis.state);
    //         //     documents[index] ? documents[index].progress = progressValue : documents[index] = { progress: progressValue };
    //         //     // setState(state);
    //         //     setDocumentState(documents);
    //         // }
    //     });
    //     console.log("Save File Result : " + JSON.stringify(result))
    //     return fileName;
    //     // .then(result => {
    //     //     console.log("Save File Result : " + JSON.stringify(result))
    //     //     let state = Object.assign({}, parentThis.state);
    //     //     // documents[index] ? documents[index].fileName = file.name : documents[index] = { fileName: file.name };
    //     //     // documents[index].s3FileName = fileName;
    //     //     // documents[index].label = label;
    //     //     // setDocumentState(documents);
    //     // })
    //     // .catch(err => {
    //     //     console.log(err)
    //     // })
    // }

    // onDrop(picture) {
    //     console.log(picture);
    //     console.log("Pictures size: " + this.state.pictures.length);
    //     this.setState({
    //         pictures: picture
    //         //pictures: this.state.pictures.concat(picture)
    //     });
    // }

    // handleDateChange(date) {
    //     // let dateStr = date instanceof moment ? date.valueOf() : "";
    //     let certificate = JSON.parse(JSON.stringify(this.state.certificate));
    //     certificate.expiryDate = date.valueOf();
    //     //moment(dateTime, 'DD/MM/YYYY:HH:mm').valueOf()
    //     this.setState({ certificate: certificate }, this.checkValid(certificate));
    // }

    // setDocumentState(name, uploads) {

    //     console.log("uploads = " + JSON.stringify(uploads));
    //     let certificate = JSON.parse(JSON.stringify(this.state.certificate));
    //     certificate.uploads = uploads;
    //     this.setState({ certificate: certificate }, this.checkValid(certificate));
    // }

    // createTableRow(prop) {
    //     // console.log("Key: '" + key + "'");
    //     console.log(JSON.stringify(prop));
    //     return {
    //         fileName: prop.fileName,
    //         s3FileName: prop.s3FileName,
    //         actions: (
    //             // we've added some custom button actions
    //             <div className="actions-right">
    //                 {/* use this button to add a like kind of action */}

    //                 {/* use this button to add a edit kind of action */}
    //                 <Button
    //                     disabled={false}
    //                     onClick={async () => {
    //                         console.log("View");
    //                         const user = await Auth.currentAuthenticatedUser();
    //                         let tenant = user.attributes["custom:tenant_id"];
    //                         if (prop.fileName) {
    //                             const customPrefix = {
    //                                 public: tenant + '/'
    //                             };


    //                             Storage.get(prop.s3FileName,
    //                                 {
    //                                     expires: 60,
    //                                     customPrefix: customPrefix
    //                                 })
    //                                 .then(result => {
    //                                     console.log("GET FILE " + result);
    //                                     this.setState({
    //                                         images: [{ src: result, alt: '', downloadUrl: result }],
    //                                         imageVisable: true
    //                                     })
    //                                 })
    //                                 .catch(err => console.log(err));
    //                         }
    //                     }}
    //                     className="btn-icon btn-round"
    //                     color="warning"
    //                     size="sm"
    //                 >
    //                     <i className="fa fa-edit" />
    //                 </Button>{" "}
    //                 {/* use this button to remove the data row */}

    //             </div>
    //         ),
    //     };
    // }


    render() {
        return (
            <>
                <Modal style={{ maxWidth: '1000px', width: '95%', margin: '10px auto' }}
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}

                >

                    <ModalHeader
                        className="justify-content-center uppercase title"
                        toggle={this.props.toggle}
                        tag="h4"

                    >
                        {this.props.newCertificate === true ? "New Certificate" : "Edit Certificate"}

                    </ModalHeader>
                    <div id="modal-container">

                    </div>
                    <ModalBody>
                        <Row>
                            <Col xs={12} md={12}>
                                <Form className="form-horizontal">
                                    <Card>
                                        <CardBody>

                                            <div className="content" >
                                                <EditCertificate
                                                    updateTable={this.props.updateTable}
                                                    newCertificate={this.props.newCertificate}
                                                    certificate={this.props.certificate}
                                                    isOpen={this.props.isOpen}
                                                    toggle={this.props.toggle}
                                                    user={this.props.user} 
                                                    equipmentId={this.props.equipmentId}
                                                    employeeId={this.props.employeeId}/>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
                <Viewer
                    visible={this.state.imageVisable}
                    onClose={() => { this.setState({ imageVisable: false }) }}
                    images={this.state.images}
                    container={document.getElementById("modal-container")}
                />
            </>
        );
    }
}

export default EditCertificateModal;
