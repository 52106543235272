/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
    Row,
    Col,
    Label,
    FormGroup,
    Input
} from "reactstrap";

import EditModalCommon from './EditModalCommon';
import EditModalBase from './EditModalBase';
import DocUploader from "components/DocUploader";



const docs = [
    {
      label: "Insurance Certificate",
      placeHolder: "Click here to upload document",
      ref: React.createRef(),
      required: true,
      expirationDateRequired: true
    },
    {
        label: "Safety Statement",
        placeHolder: "Click here to upload document",
        ref: React.createRef(),
        required: true,
        expirationDateRequired: true
    }
];

const initSubContractor = {
    name: "",
    documents: []
};

class EditSubContractorModal extends EditModalCommon {
    constructor(props) {
        super(props, {
            domainObject: "subcontractor",
            subcontractor: initSubContractor
        }, initSubContractor
        );

        this.checkValid = this.checkValid.bind(this);
    }

    checkValid(subcontractor) {
        console.log("checkValid " + JSON.stringify(subcontractor));
        let { name } = subcontractor;

        let valid = true;
        if (!name) {
            valid = false;
        }
        return valid;
    }


    render() {
        return (
            <>
                <EditModalBase save={this.save}
                    addHeader={true}
                    valid={this.state.valid}
                    domainObject="subcontractor"
                    modalType="Sub Contractor"
                    updateTable={this.props.updateTable}
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    newRecord={this.props.newRecord}
                    record={this.props.record}
                    initRecord={this.initLoation}>
                    <Row>
                        <Label sm={2}>Name</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Input
                                    value={this.state.subcontractor.name}
                                    type="required"
                                    onChange={this.valueChange}
                                    name="name"
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row>
                    <DocUploader
                        docs={docs}
                        documents={this.state.subcontractor.documents}
                        docArrayName="documents"
                        user={this.props.user}
                        disabled={this.props.disabled}
                        id={this.props.user.attributes["custom:tenant_id"]}
                        setDocumentState={this.setDocumentState}
                      />
                    <div id="modal-container"></div>

                </EditModalBase>
            </>
        );
    }
}

export default EditSubContractorModal;