/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";


import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v=1.4.0";
import "assets/css/demo.css";

import Amplify from "aws-amplify";
import config from "./config";
import App from './App';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
        {
            name: config.api.API_NAME,
            endpoint: config.api.API_ENDPOINT
        }
    ]
  },
  AWSS3: {
    bucket: config.s3.BUCKET_NAME,//Your bucket name;
    region: config.cognito.REGION//Specify the region your bucket was created in;
  }
});



ReactDOM.render(<App />, document.getElementById('root'));
