/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import LoginPage from "views/Pages/LoginPage.js";
import ForgotPasswordPage from "views/Pages/ForgotPasswordPage.js";
import ForgotPasswordSubmitPage from "views/Pages/ForgotPasswordSubmitPage.js";
import ChangePassword from "views/Pages/ChangePassword.js";
import RegisterPage from "views/Pages/RegisterPage.js";

let routes = [
 
  {
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openPages",
    icon: "now-ui-icons design_image",
    views: [
      {
        path: "/register-page",
        name: "Register Page",
        short: "Register",
        mini: "RP",
        component: RegisterPage,
        layout: "/auth",
      },
      {
        path: "/login-page",
        name: "Login Page",
        short: "Login",
        mini: "LP",
        component: LoginPage,
        layout: "/auth",
      },
      {
        path: "/forgot-password",
        name: "Forgot Password",
        short: "Forgot",
        mini: "FP",
        component: ForgotPasswordPage,
        layout: "/auth"
      },
      {
        path: "/forgot-password-submit",
        name: "Forgot Password Submit",
        short: "Forgot Submit",
        mini: "FP",
        component: ForgotPasswordSubmitPage,
        layout: "/auth"
      },
      {
        path: "/change-pass",
        name: "Change Password",
        short: "Password",
        mini: "CP",
        component: ChangePassword,
        layout: "/auth"
      },
    ],
  },
];

export default routes;
