/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
    Row,
    Col,
    Label,
    FormGroup,
    Input
} from "reactstrap";

import EditModalCommon from './EditModalCommon';
import EditModalBase from './EditModalBase';
import { listData } from "../../util/ListPageUtil";
import Select from "react-select";


const initEmployee = {
    internalId: "",
    firstName: "",
    lastName: "",
    email: "",
    positionId: "",
    locationId: ""
};

class EditEmployeeModal extends EditModalCommon {
    constructor(props) {
        super(props, {
            domainObject: "employee",
            employee: initEmployee,
            posSelect: [],
            locSelect: []
        }, initEmployee
        );

        this.checkValid = this.checkValid.bind(this);
    }

    checkValid(employee) {
        console.log("checkValid " + JSON.stringify(employee));
        let { internalId, firstName, lastName, locationId, positionId, email } = employee;

        let valid = true;
        if (!internalId || !firstName || !lastName ) {
            valid = false;
        }
        return valid;
    }

    async componentDidMount() {
        console.log("ComponentDidMount " + this.state.domainObject);

        const locations = await listData("/locations");
        const positions = await listData("/positions");

        const locSelect = locations.map(l => { 
            let obj = {}; 
            obj.label = l.name;
            obj.value = l.id;
            return obj;
        });
        const posSelect = positions.map(p => { 
            let obj = {}; 
            obj.label = p.name;
            obj.value = p.id;
            return obj;
        });


        let state = Object.assign({}, this.state);

        state.locSelect = locSelect;
        state.posSelect = posSelect;
        state.initRecord.locationId = locSelect.length === 1 ? locSelect[0].value : "";
        state.initRecord.positionId = posSelect.length === 1 ? posSelect[0].value : "";

        this.setState(state);
    }


    render() {
        return (
            <>
                <EditModalBase save={this.save}
                    addHeader={true}
                    valid={this.state.valid}
                    domainObject="employee"
                    modalType="Employee"
                    updateTable={this.props.updateTable}
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    newRecord={this.props.newRecord}
                    record={this.props.record}
                    initRecord={this.initEmployee}>
                    <Row>
                        <Label sm={2}>ID Number</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Input
                                    value={this.state.employee.internalId}
                                    type="required"
                                    onChange={this.valueChange}
                                    name="internalId"
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row>
                    <Row>
                        <Label sm={2}>First Name</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Input
                                    value={this.state.employee.firstName}
                                    type="required"
                                    onChange={this.valueChange}
                                    name="firstName"
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row>
                    <Row>
                        <Label sm={2}>Last Name</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Input
                                    value={this.state.employee.lastName}
                                    type="required"
                                    onChange={this.valueChange}
                                    name="lastName"
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row>
                    <Row>
                        <Label sm={2}>Email</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Input
                                    value={this.state.employee.email}
                                    onChange={this.valueChange}
                                    name="email"
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            {/* <code>*</code> */}
                        </Label>
                    </Row>
                    <Row>
                        <Col xs={12} sm={7}>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        id="accept"
                                        value={this.state.accept}
                                        onChange={() => this.setState({ addCertificates: true })}
                                    />
                                    <span className="form-check-sign" />
                                    <div>
                                        Add Certificates?
                                    </div>
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Label sm={2}>Location</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Select
                                    className="primary react-select"
                                    classNamePrefix="react-select"
                                    placeholder="Please select"
                                    value={this.state.locSelect.find((o) => o.value === this.state.employee.locationId)}
                                    options={this.state.locSelect}                                    
                                    onChange={(value) => this.valueChangeBase("locationId", value.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row>
                    <Row>
                        <Label sm={2}>Position</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Select
                                    className="primary react-select"
                                    classNamePrefix="react-select"
                                    placeholder="Please select"
                                    value={this.state.posSelect.find((o) => o.value === this.state.employee.positionId)}
                                    options={this.state.posSelect}                        
                                    onChange={(value) => this.valueChangeBase("positionId", value.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row> */}

                </EditModalBase>
            </>
        );
    }
}

export default EditEmployeeModal;