import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { createBrowserHistory } from "history";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import routesSchoolAdmin from "routesSchoolAdmin.js";
import routesCompanyAdmin from "routesCompanyAdmin.js";
import routesSuperAdmin from "routesSuperAdmin.js";
import { getQueryVariable } from "util/WindowUtil"



const hist = createBrowserHistory();

class App extends Component {



  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null
  }

  setAuthStatus = authenticated => {
    this.setState({
      isAuthenticated: authenticated,
      password: ""
    });
  }

  setUser = user => {
    this.setState({ user: user });
  }

  setGroup = group => {
    this.setState({ group: group });
  }


  async componentDidMount() {
    let groups;

    try {
      const username = getQueryVariable('username');
      const password = getQueryVariable('password');
      console.log("username : " + username);
      if (username) {
        this.setState({
          username: username,
          password: password,
        });
      }

      const id = getQueryVariable('id')
      console.log("id : " + id);
      if (id) {
        this.setState({ id: id });
      }

      const currentSession = await Auth.currentSession();
      // console.log("CurrentSession: "+JSON.stringify(currentSession));
      const user = await Auth.currentAuthenticatedUser();
      groups = user.signInUserSession.accessToken.payload['cognito:groups'];
      console.log("User &******& " + JSON.stringify(groups))








      //console.log(this.props);
      // const query = new URLSearchParams(window.location);
      let email = user.attributes["email"];

      if (username && username !== email) {
        //log the user out
        await Auth.signOut();
      } else {
        this.setUser(user);
        this.setAuthStatus(true);
      }

    } catch (error) {
      if (error !== 'No current user') {
        console.log(error);
      }
    }

    this.setState({ isAuthenticating: false, group: groups ? groups[0] : "COMPANY" });
  }

  componentWillReceiveProps() {

    console.log("compomnenmet will receive props");

  }



  static async getDerivedStateFromProps(props, state) {
    const user = await Auth.currentAuthenticatedUser();
    const  groups = user.signInUserSession.accessToken.payload['cognito:groups'];
    console.log("User &******& " + JSON.stringify(groups))
    // const userData = localStorage.getItem("userData");
    // const user = JSON.parse(userData);

    if (state.groups !== groups) {
      return {
        group: groups ? groups[0] : "COMPANY"
      }
    }
    return null;
  }


  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser,
      setGroup: this.setGroup,
      username: this.state.username,
      password: this.state.password,
      id: this.state.id
    }

    const group = this.state.group; 

    return (
      !this.state.isAuthenticating &&
      <div className="App">
        <Router history={hist}>
          <Switch>
            <Route path="/admin" render={props => { return this.state.isAuthenticated ? <AdminLayout routes={(group === 'COMPANY' || group === 'SUPER') ? (group === 'SUPER' ? routesSuperAdmin : routesCompanyAdmin) : routesSchoolAdmin} {...props} auth={authProps} /> : <Redirect to="/auth/login-page" />; }} />
            <Route path="/auth" render={props => { return <AuthLayout {...props} authPro={authProps} />; }} />
            {/* <Redirect to="/admin/dashboard" /> */}
            <Redirect to="/auth/login-page" />
          </Switch>
        </Router>
      </div>
    );
  }
}


export default App;