/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap";
import { Auth, API } from 'aws-amplify';
import config from "../../config";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";



import EditCourseModal from "./EditCourseModal.js";
import InviteCourseModal from "./InviteCourseModal.js";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";


class ListCourses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editModal: false,
            inviteModal: false,
            data: [],
            inviteCourse: {},
            alert: null

        };
        this.updateTable = this.updateTable.bind(this);
    }

    toggleEditModal = (newCourse, course) => {
        this.setState({
            editModal: !this.state.editModal,
            newCourse: newCourse,
            course: course
        });
    };

    toggleInviteModal = (course) => {
        this.setState({
            inviteModal: !this.state.inviteModal,
            inviteCourse: course
        });
    };

    updateTable(course) {
        let data = Object.assign([], this.state.data);
        let courses = Object.assign([], this.state.courses);
        
        let origLen = data.length;
        data = data.filter((o) => o.id !== course.id);

        if(data.length === origLen) {
            //this is not an existing course
            courses.push(course);
            this.setState({
                courses: courses
            });
        }

        data = data.concat(this.createTableRow(course, data.length + 1));

        this.setState({
            data: data,
        });
    }

    async deleteCourseById(id) {
        console.log("deleteCourseById "+id);

        const user = await Auth.currentAuthenticatedUser();
        const jwtToken = user.signInUserSession.idToken.jwtToken;

        // console.log(jwtToken);

        let apiName = config.api.API_NAME;
        let path = '/courses/' + encodeURIComponent(id);
        let myInit = { // OPTIONAL
            headers: {
                Authorization: "Bearer " + jwtToken
            }, // OPTIONAL
            response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            // queryStringParameters: {  // OPTIONAL
            //     id: course.sk
            // }
        }
        API.del(apiName, path, myInit).then(response => {
            console.log("Respone : " + JSON.stringify(response));



        }).catch(error => {
            console.log(error);
        });
    }

    deleteCourse(courseSk, courseId) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={async () => {
                        let data = Object.assign([], this.state.data);
                        this.deleteCourseById(courseSk);
                        data = data.filter((o) => o.id !== courseId);
                        this.setState({ data: data });
                        this.hideAlert();
                    }}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, delete them!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    This will Delete ALL course dates.
                </SweetAlert>
            )
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    createTableRow(prop, key) {
        let data = [];
        Object.entries(prop.daTimes).map(([dt, obj]) => {

            data.push({
                id: prop.id,

                name: prop.title,
                status: prop.status && prop.status.label ? prop.status.label : prop.status,
                location: prop.location && prop.location.label ? prop.location.label : prop.location,
                dateTime: moment(parseInt(dt)).format("DD MMM YYYY hh:mm a"),
                filled: ((obj.noParticipants - (obj.noFilled ? obj.noFilled : 0)) + " / " + obj.noParticipants),
                actions: (
                    // we've added some custom button actions
                    <div className="actions-right">
                        {/* use this button to add a like kind of action */}

                        {/* use this button to add a edit kind of action */}
                        <Button
                            onClick={() => {
                                // let obj = this.state.data.find((o) => o.id === key);
                                this.toggleInviteModal(prop);
                            }}
                            className="btn-icon btn-round"
                            color="success"
                            size="sm"
                        >
                            <i className="fa fa-heart" />
                        </Button>{" "}
                        <Button
                            onClick={() => {
                                // let obj = this.state.data.find((o) => o.id === key);
                                // console.log("This is the course to edit : " + JSON.stringify(obj));
                                this.toggleEditModal(false, prop);
                            }}
                            className="btn-icon btn-round"
                            color="warning"
                            size="sm"
                        >
                            <i className="fa fa-edit" />
                        </Button>{" "}
                        {/* use this button to remove the data row */}
                        <Button
                            disabled={false}
                            onClick={() => this.deleteCourse(prop.sk, prop.id)}
                            className="btn-icon btn-round"
                            color="danger"
                            size="sm"
                        >
                            <i className="fa fa-times" />
                        </Button>{" "}
                    </div>
                ),
            });
        });
        return data;
    }

    async componentDidMount() {
        console.log("ComponentDidMount");

        const user = await Auth.currentAuthenticatedUser();
        const jwtToken = user.signInUserSession.idToken.jwtToken;

        // console.log(jwtToken);

        let apiName = config.api.API_NAME;
        let path = '/courses';
        let myInit = { // OPTIONAL
            headers: {
                Authorization: "Bearer " + jwtToken
            }, // OPTIONAL
            response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {  // OPTIONAL
                id: 123
            }
        }
        API.get(apiName, path, myInit).then(response => {
            // console.log("Respone : " + JSON.stringify(response));
            let data = [];
            response.map((prop, key) => {
                data = data.concat(this.createTableRow(prop, key));
            });
            // console.log(JSON.stringify(data));
            this.setState({
                data: data,
                courses: response
            });
        }).catch(error => {
            console.log(error);
        });


    }

    render() {
        return (
            <>
                <EditCourseModal updateTable={this.updateTable} isOpen={this.state.editModal} toggle={this.toggleEditModal} newCourse={this.state.newCourse} course={this.state.course} />
                <InviteCourseModal isOpen={this.state.inviteModal} toggle={this.toggleInviteModal} course={this.state.inviteCourse} />
                <PanelHeader
                    size="sm"
                    content={
                        <div className="header text-center">
                            {/* <h2 className="title">Courses</h2> */}
                            {/* <p className="category">
                A powerful react plugin handcrafted by our friends from{" "}
                <a
                  href="https://react-table.js.org/#/story/readme"
                  target="_blank"
                >
                  react-table
                </a>
                . It is a highly flexible tool, based upon the foundations of
                progressive enhancement on which you can add advanced
                interaction controls. Please check out their{" "}
                <a
                  href="https://react-table.js.org/#/story/readme"
                  target="_blank"
                >
                  full documentation.
                </a>
              </p> */}
                        </div>
                    }
                />
                <div className="content">
                    {this.state.alert}
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Courses
                                        <Button color="info" className="btn-round float-right" size="sm"
                                            onClick={() => this.toggleEditModal(true, null)}
                                        >
                                            <i className="now-ui-icons ui-2_favourite-28" /> New Course
                                        </Button>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.state.data}
                                        columns={[
                                            {
                                                Header: "Course Name",
                                                accessor: "name",
                                            },
                                            {
                                                Header: "Status",
                                                accessor: "status",
                                            },
                                            {
                                                Header: "Date/Time",
                                                accessor: "dateTime",
                                            },
                                            {
                                                Header: "Filled / Positions",
                                                accessor: "filled",
                                            },
                                            {
                                                Header: "Actions",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                            },
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default ListCourses;
