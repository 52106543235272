import { Auth, API } from 'aws-amplify';
import config from "../config";


export async function listData(path) {
    console.log("ComponentDidMount");

    const user = await Auth.currentAuthenticatedUser();
    const jwtToken = user.signInUserSession.idToken.jwtToken;

    // console.log(jwtToken);

    let apiName = config.api.API_NAME;
    let myInit = { // OPTIONAL
        headers: {
            Authorization: "Bearer " + jwtToken
        }, // OPTIONAL
        response: false // OPTIONAL (return the entire Axios response object instead of only response.data)
    }
    let resp = {};
    await API.get(apiName, path, myInit).then(response => {
        console.log("Respone : " + JSON.stringify(response));
        resp = response;
    }).catch(error => {
        console.log(error);
    });
    return resp;

}

// export function toggleEditModal(setState, domainName, newDomainName, newDomain, domain, editModal) {
//     setState({
//         editModal: !editModal,
//         [newDomainName]: newDomain,
//         [domainName]: domain
//     });
// };




