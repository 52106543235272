
/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Row,
    Col,
    Form,
    Card,
    CardBody,
} from "reactstrap";

class EditModalBase extends Component {
    constructor(props) {
        super(props);
    }



    render() {
        return (
            <>
                <Modal style={{ maxWidth: '1000px', width: '95%', margin: '10px auto' }}
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}

                >
                    {this.props.addHeader &&
                        <ModalHeader
                            className="justify-content-center uppercase title"
                            toggle={this.props.toggle}
                            tag="h4"

                        >
                            {(this.props.newRecord === true ? "New " : "Edit ") + this.props.modalType}

                        </ModalHeader>
                    }
                    <ModalBody>
                        <div className="content" >
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form className="form-horizontal">
                                        <Card>
                                            <CardBody>
                                                {this.props.children}
                                            </CardBody>
                                        </Card>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {this.props.save &&
                            <Button disabled={!this.props.valid} color="success"
                                onClick={this.props.save} >Save</Button>
                        }
                        <Button
                            color="danger"
                            onClick={this.props.toggle}
                        >
                            Close
                          </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }



}

export default EditModalBase;
