/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
    Row,
    Col,
    Button,
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    UncontrolledTooltip
} from "reactstrap";

import EditModalCommon from './EditModalCommon';
import EditModalBase from './EditModalBase';
import ReactTable from "components/ReactTable/ReactTable.js";
import { Auth, API } from 'aws-amplify';
import config from "../../config";
import moment from "moment";


import { listData } from "../../util/ListPageUtil";
import Select from "react-select";
import EditCertificateModal from "pages/courses/EditCertificateModal";


const initEmployee = {
    internalId: "",
    firstName: "",
    lastName: "",
    email: "",
    positionId: "",
    locationId: "",
    documents: []
};

class ListCertificatesModal extends EditModalCommon {
    constructor(props) {
        super(props, {

            data: []
        }, initEmployee
        );

        this.checkValid = this.checkValid.bind(this);
        this.updateTable = this.updateTable.bind(this);
    }

    checkValid(employee) {
        console.log("checkValid " + JSON.stringify(employee));
        let { internalId, firstName, lastName, locationId, positionId, email } = employee;

        let valid = true;
        if (!internalId || !firstName || !lastName || !locationId || !positionId || !email) {
            valid = false;
        }
        return valid;
    }

    toggleEditModal = (newCertificate, certificate) => {
        this.setState({
            editModal: !this.state.editModal,
            newCertificate: newCertificate,
            certificate: certificate
        });
    };

    toggleArchiveModal = (newCertificate, certificate) => {
        this.setState({
            archiveModal: !this.state.archiveModal,
            certificate: certificate
        });
    };

    showArchive = (cert, index) => {
        console.log("showArchive -- index:  " + index + " cert: " + JSON.stringify(cert));
        let data2 = null;
        let selectedRow = null;
        if (index !== this.state.selectedRow) {
            selectedRow = index;
            this.loadCertificates('/certificates/certArchive/' + encodeURIComponent(cert.sk), "data2", true, selectedRow);
        }

        this.setState({
            data2: null,
            message: "Loading ...",
            selectedRow: selectedRow
        });
    };



    updateTable(certificate) {
        let data = Object.assign([], this.state.data);
        let certificates = Object.assign([], this.state.certificates);

        let origLen = data.length;
        data = data.filter((o) => o.id !== certificate.id);

        if (data.length === origLen) {
            //this is not an existing course
            certificates.push(certificate);
            this.setState({
                certificates: certificates
            });
        }




        data.push(this.createTableRow(certificate, data.length + 1));

        this.setState({
            data: data
        });
    }


    createTableRow(prop, key, history) {
        // console.log("prop == " + JSON.stringify(prop));
        return {
            id: prop.id,
            name: prop.name,
            status: prop.status && prop.status.label ? prop.status.label : prop.status,
            dateTime: prop.expiryDate ? moment(parseInt(prop.expiryDate)).format("DD MMM YYYY") : "",
            attachments: prop.uploads ? prop.uploads.length : 0,
            dateUpdated: moment(parseInt(prop._md * 1000)).format("DD MMM YYYY"),
            actions: (
                // we've added some custom button actions
                <div className="actions-right">
                    {/* use this button to add a like kind of action */}
                    {!history &&
                        <>
                            <Button
                                onClick={() => {
                                    // let obj = this.state.data.find((o) => o.id === key);
                                    // console.log("This is the certificate to edit : " + JSON.stringify(obj));
                                    this.toggleEditModal(false, prop);
                                }}
                                className="btn-icon btn-round"
                                color="warning"
                                size="sm"
                                id={"edit" + key}
                            >
                                <i className="fa fa-edit" />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target={"edit" + key}
                                trigger={"hover"}
                            >
                                Edit
                            </UncontrolledTooltip>{" "}
                            {/* use this button to remove the data row */}
                            <Button
                                disabled={false}
                                onClick={() => this.toggleArchiveModal(false, prop)}
                                className="btn-icon btn-round"
                                color="danger"
                                size="sm"
                                id={"delete" + key}
                            >
                                <i className="fa fa-times" />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target={"delete" + key}
                                trigger={"hover"}
                            >
                                Delete
                            </UncontrolledTooltip>{" "}
                            <Button
                                onClick={() => {
                                    // let obj = this.state.data.find((o) => o.id === key);
                                    // console.log("This is the certificate to edit : " + JSON.stringify(obj));
                                    this.showArchive(prop, key);
                                }}
                                className="btn-icon btn-round"
                                color="warning"
                                size="sm"
                                id={"archive" + key}
                            >
                                <i className="fa fa-box-open" />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target={"archive" + key}
                                trigger={"hover"}
                            >
                                Toggle History
                            </UncontrolledTooltip>{" "}
                        </>
                    }
                    {history === true &&
                        <>
                            <Button
                                onClick={() => {
                                    // let obj = this.state.data.find((o) => o.id === key);
                                    // console.log("This is the certificate to edit : " + JSON.stringify(obj));
                                    prop.canEdit = false;
                                    this.toggleEditModal(false, prop);
                                }}
                                className="btn-icon btn-round"
                                color="warning"
                                size="sm"
                                id={"edit" + key}
                            >
                                <i className="fa fa-edit" />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target={"edit" + key}
                                trigger={"hover"}
                            >
                                View
                            </UncontrolledTooltip>{" "}
                        </>

                    }
                </div>
            ),
        };
    }

    async loadCertificates(path, stateDataName, history, selectedRow) {
        console.log("loadCertificates " + stateDataName + " path: " + path);

        const user = await Auth.currentAuthenticatedUser();
        const jwtToken = user.signInUserSession.idToken.jwtToken;

        // console.log(jwtToken);

        let apiName = config.api.API_NAME;
        let myInit = { // OPTIONAL
            headers: {
                Authorization: "Bearer " + jwtToken
            }, // OPTIONAL
            response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
        }
        API.get(apiName, path, myInit).then(response => {
            console.log("Respone : " + JSON.stringify(response));
            let data = response.map((prop, key) => this.createTableRow(prop, key, history));
            if(stateDataName === "data2") {
                data = data.reverse();
            }
            // console.log(JSON.stringify(data));
            this.setState({
                data2: null, //initialize the second table                
                [stateDataName]: data,
                message: data.length > 0 ? null : "No History Found",
                certificates: response,
                selectedRow: selectedRow
            });
        }).catch(error => {
            console.log(error);
        });
    }

    componentDidUpdate(prevProps) {
        const { employeeId, equipmentId } = this.props;
        console.log("employeeId: " + employeeId);
        if ((employeeId && !prevProps.employeeId) || (employeeId && prevProps.employeeId && prevProps.employeeId !== employeeId)) {
            this.loadCertificates('/employees/' + employeeId + '/certificates', "data", null);
        } else if ((equipmentId && !prevProps.equipmentId) || (equipmentId && prevProps.equipmentId && prevProps.equipmentId !== equipmentId)) {
            this.loadCertificates('/equipments/' + equipmentId + '/certificates', "data", null);
        }

    }


    render() {
        return (
            <>
                <EditCertificateModal
                    equipmentId={this.props.equipmentId}
                    employeeId={this.props.employeeId}
                    updateTable={this.updateTable}
                    isOpen={this.state.editModal}
                    toggle={this.toggleEditModal}
                    newCertificate={this.state.newCertificate}
                    certificate={this.state.certificate}
                    user={this.props.auth.user}
                    setDocumentState={this.setDocumentState}
                />
                <EditModalBase
                    addHeader={false}
                    valid={this.state.valid}
                    domainObject="certificate"
                    modalType="Certificates"
                    updateTable={this.props.updateTable}
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    newRecord={this.props.newRecord}
                    record={this.props.record}
                    initRecord={this.initEmployee}>
                    <Row>
                        <Col xs={12} md={12}>
                            <CardHeader>
                                <CardTitle tag="h4">Certificates
                                    <Button color="info" className="btn-round float-right" size="sm"
                                        onClick={() => this.toggleEditModal(true, null)}
                                    >
                                        <i className="now-ui-icons ui-2_favourite-28" /> New Certificate
                                    </Button>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                {(this.state.data && this.state.data.length > 0) &&
                                    <ReactTable
                                        childColspan="4"
                                        data={this.state.data}
                                        selectedRow={this.state.selectedRow}
                                        columns={[
                                            {
                                                Header: "Certificate Name",
                                                accessor: "name",
                                            },
                                            {
                                                Header: "Expiry Date",
                                                accessor: "dateTime",
                                            },
                                            {
                                                Header: "Last Updated",
                                                accessor: "dateUpdated",
                                            },
                                            {
                                                Header: "Attachments",
                                                accessor: "attachments",
                                            },
                                            {
                                                Header: "Actions",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                            },
                                        ]}
                                    >
                                        {(this.state.data2 && this.state.data2.length > 0) &&
                                            <ReactTable
                                                data={this.state.data2}
                                                showFilter={false}
                                                columns={[
                                                    {
                                                        Header: "Certificate Name",
                                                        accessor: "name",
                                                    },
                                                    {
                                                        Header: "Expiry Date",
                                                        accessor: "dateTime",
                                                    },
                                                    {
                                                        Header: "Last Updated",
                                                        accessor: "dateUpdated",
                                                    },
                                                    {
                                                        Header: "Attachments",
                                                        accessor: "attachments",
                                                    },
                                                    {
                                                        Header: "Actions",
                                                        accessor: "actions",
                                                        sortable: false,
                                                        filterable: false,
                                                    },
                                                ]}
                                            />
                                        }
                                        {((!this.state.data2 || this.state.data2.length === 0) && this.state.message !== null) &&
                                            <>
                                                {this.state.message}
                                            </>
                                        }
                                    </ReactTable>
                                }
                            </CardBody>
                        </Col>
                    </Row>

                </EditModalBase>
            </>
        );
    }
}

export default ListCertificatesModal;