/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
    Row,
    Col,
    Label,
    FormGroup,
    Input
} from "reactstrap";

import EditModalCommon from './EditModalCommon';
import EditModalBase from './EditModalBase';


const initContract = {
    name: "",
};

class EditContractModal extends EditModalCommon {
    constructor(props) {
        super(props, {
            domainObject: "contract",
            contract: initContract
        }, initContract
        );

        this.checkValid = this.checkValid.bind(this);
    }

    checkValid(contract) {
        console.log("checkValid " + JSON.stringify(contract));
        let { name } = contract;

        let valid = true;
        if (!name) {
            valid = false;
        }
        return valid;
    }


    render() {
        return (
            <>
                <EditModalBase save={this.save}
                    addHeader={true}
                    valid={this.state.valid}
                    domainObject="contract"
                    modalType="Contract"
                    updateTable={this.props.updateTable}
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    newRecord={this.props.newRecord}
                    record={this.props.record}
                    initRecord={this.initLoation}>
                    <Row>
                        <Label sm={2}>Name</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Input
                                    value={this.state.contract.name}
                                    type="required"
                                    onChange={this.valueChange}
                                    name="name"
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row>

                </EditModalBase>
            </>
        );
    }
}

export default EditContractModal;