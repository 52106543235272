/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ListCourses from "pages/courses/ListCourses.js";
import ListBookings from "pages/courses/ListBookings.js";
import ListInvites from "pages/courses/ListInvites.js";
import ListCertificates from "pages/courses/ListCertificates.js";
import ListEmployees from "pages/organisation/ListEmployees.js";
import ListPositions from "pages/organisation/ListPositions.js";
import ListLocations from "pages/organisation/ListLocations.js";
import ListEquipment from "pages/organisation/ListEquipment";
import ListContracts from "pages/organisation/ListContracts";
import ListSubContractors from "pages/organisation/ListSubContractors";
import ListSCEmployees from "pages/organisation/subContractors/ListSCEmployees";
import ListSCEquipment from "pages/organisation/subContractors/ListSCEquipment";
import ListDocuments from "pages/organisation/ListDocuments";
import ReportPage from "pages/organisation/ReportPage"
import CompanySettings from "pages/settings/CompanySettings";


let routes = [
  {
    collapse: true,
    path: "/admin",
    name: "My Company",
    state: "openOrganisation",
    icon: "now-ui-icons design-2_ruler-pencil",
    views: [
      // {
      //   path: "/list-certificates",
      //   name: "List Certificates",
      //   mini: "LC",
      //   component: ListCertificates,
      //   layout: "/admin",
      // },
      {
        path: "/list-employees",
        name: "List Employees",
        mini: "LE",
        component: ListEmployees,
        layout: "/admin",
      },
      {
        path: "/list-equipment",
        name: "List Equipment",
        mini: "LQ",
        component: ListEquipment,
        layout: "/admin",
      },
      {
        path: "/list-documents",
        name: "List Documents",
        mini: "LD",
        component: ListDocuments,
        layout: "/admin",
      },
      {
        path: "/reports",
        name: "Reports",
        mini: "RS",
        component: ReportPage,
        layout: "/admin",
      },
      {
        path: "/settings",
        name: "Settings",
        mini: "SC",
        component: CompanySettings,
        layout: "/admin",
      },
      
      
      // {
      //   path: "/list-contract",
      //   name: "List Contracts",
      //   mini: "LC",
      //   component: ListContracts,
      //   layout: "/admin",
      // },
      // {
      //   path: "/list-location",
      //   name: "List Locations",
      //   mini: "LL",
      //   component: ListLocations,
      //   layout: "/admin",
      // },
      // {
      //   path: "/list-positions",
      //   name: "List Positions",
      //   mini: "LP",
      //   component: ListPositions,
      //   layout: "/admin",
      // }
    ]
  },
  // {
  //   collapse: true,
  //   path: "/subcontractors",
  //   name: "Sub-Contractors",
  //   state: "openSubContractors",
  //   icon: "now-ui-icons design-2_ruler-pencil",
  //   views: [
  //     {
  //       path: "/list-subcontractors",
  //       name: "List Sub-Contractors",
  //       mini: "LS",
  //       component: ListSubContractors,
  //       layout: "/admin",
  //     },
      // {
      //   path: "/list-sc-employees",
      //   name: "List Employees",
      //   mini: "LE",
      //   component: ListSCEmployees,
      //   layout: "/admin",
      // },
      // {
      //   path: "/list-sc-equipment",
      //   name: "List Equipment",
      //   mini: "LQ",
      //   component: ListSCEquipment,
      //   layout: "/admin",
      // }      
  //   ]
  // }
];

export default routes;
