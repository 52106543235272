import React from "react";
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Progress
} from "reactstrap";
import PropTypes from "prop-types";
import { Storage } from 'aws-amplify';
import uuid from "uuid";
import Viewer from 'react-viewer';
import DisplayPDFModal from "./DisplayPDFModal";
import Datetime from "react-datetime";
import moment from 'moment';
import contentDisposition from 'content-disposition';








class DocUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pdfDataModalOpen: false,
      imgHeight: 0
    }

    this.togglePDFData = this.togglePDFData.bind(this);
    this.saveFile = this.saveFile.bind(this);
  }

  handleDownload = async (event, index) => {
    event.preventDefault();
    //console.log("Download File "+JSON.stringify(this.state.documents[index]));
    const customPrefix = {
      public: this.props.id + '/'
      // protected: 'myProtectedPrefix/',
      // private: 'myPrivatePrefix/'
    };
    Storage.get(this.props.documents[index].s3FileName,
      {
        expires: 60,
        customPrefix: customPrefix
      })
      .then(result => {
        console.log(result)
        //let blob=new Blob([data.Body], {type: data.ContentType});
        let link = document.createElement('a');
        link.href = result;
        link.download = result;
        link.click();


      })
      .catch(err => console.log(err));
  }

  handlePreview = async (event, index) => {
    event.preventDefault();
    console.log("Preview: " + JSON.stringify(this.props.documents[index]));

    const customPrefix = {
      public: this.props.id + '/'
    };
    Storage.get(this.props.documents[index].s3FileName,
      {
        expires: 60,
        customPrefix: customPrefix
      })
      .then(result => {
        console.log(result);
        let fileName = this.props.documents[index].fileName.toLowerCase();

        if (fileName.indexOf(".pdf") !== -1) {
          this.setState({
            pdfData: result,
            pdfDataModalOpen: true
            // images: [{ src: result, alt: '', downloadUrl: result }],
            // imageVisable: true
          });
        } else if (fileName.indexOf(".jpeg") !== -1 || fileName.indexOf(".png") !== -1 || fileName.indexOf(".jpg") !== -1 || fileName.indexOf(".gif") !== -1 || fileName.indexOf(".bmp") !== -1) {
          this.setState({
            images: [{ src: result, alt: '', downloadUrl: result }],
            imageVisable: true,
            imgHeight: 1000
          });

        }
      })
      .catch(err => console.log(err));
  }

  togglePDFData() {
    let pdfDataModalOpen = this.state.pdfDataModalOpen;
    this.setState({
      pdfDataModalOpen: !pdfDataModalOpen
    });
  }

  // // // to understand the bellow functions please take a look at the end of the document as well
  // this handles the onFocus event over the normal / visible Inputs (reactstrap components)
  // that will trigger the on click of the non-visible inputs (normal html component)
  handleFileInput = (e, index) => {
    console.log(this.props.docs[index]);
    this.props.docs[index].ref.current.click(e);
  };
  // this is the function triggered for the file type input
  // it will store inside the components state the names of the files and the files
  // after that, if you want to save the files you need to add a special on submit function for that
  // we haven't since we do not need to save the files - everything in our product is only front-end
  addFile = (e, index, label) => {
    let files = e.target.files;
    if (!files[0]) {
      console.error("No files " + files);
      return;
    }

    this.saveFile(files[0], index);

    // let documents = this.props.documents;
    // documents[index] ? documents[index].fileName = files[0].name : documents[index] = { fileName: files[0].name };
    // documents[index].label = label;
    // this.props.setDocumentState(this.props.docArrayName, documents);
  };

  saveFile = (file, index) => {
    console.log("Save File id:" + this.props.id);
    if (!file) {
      console.error("No file " + file);
      return;
    }

    const parentThis = this;

    // console.log(this.props);
    // console.log(this.state);
    const customPrefix = {
      public: this.props.id + '/'
      // protected: 'myProtectedPrefix/',
      // private: 'myPrivatePrefix/'
    };
    let fileName = uuid.v4();
    let documents = this.props.documents;
    Storage.put(fileName, file, {
      level: 'public',   //default
      customPrefix: customPrefix,
      // contentDisposition: "attachment; filename="+file.name+"",
      contentDisposition: contentDisposition(file.name.replace(/[^\x00-\x7F]/g, "_")),
      progressCallback(progress) {
        let progressValue = Math.round(progress.loaded / progress.total * 100);
        documents[index] ? documents[index].progress = progressValue : documents[index] = { progress: progressValue };
        parentThis.props.setDocumentState(parentThis.props.docArrayName, documents);
      }
    })
      .then(result => {
        console.log("Save File Result : " + JSON.stringify(result))
        documents[index] ? documents[index].fileName = file.name : documents[index] = { fileName: file.name };
        documents[index].s3FileName = fileName;
        this.props.setDocumentState(this.props.docArrayName, documents);

      })
      .catch(err => {
        console.log(err)
      })
  }





  render() {
    let docs = this.props.docs;
    let documents = this.props.documents;
    let modalContainerId = this.props.modalContainerId ? this.props.modalContainerId : "modal-container";
    console.log("modalContainerId : "+modalContainerId);
    return (
      <>
        <DisplayPDFModal
          pdfData={this.state.pdfData}
          isOpen={this.state.pdfDataModalOpen}
          toggle={this.togglePDFData}
        />
        {/* <div id="modal-container-orig" style={{ height: this.state.imgHeight }}> */}
        <Viewer
          visible={this.state.imageVisable}
          onClose={() => { this.setState({ imageVisable: false, imgHeight: 0 }) }}
          images={this.state.images}
          container={document.getElementById(modalContainerId)}
        />


        {/* </div> */}
        {docs.map((doc, index) => {
          let docTypeRequired = doc.docTypeRequired === true ? doc.docTypeRequired : false;
          let expirationDateRequired = doc.expirationDateRequired === true ? doc.expirationDateRequired : false;
          let success = "has-success";
          let danger = "has-danger";
          if (doc.required === false) {
            success = "";
            danger = "";
          }

          return (
            <Row key={index} className={this.props.rowClass ? this.props.rowClass : "justify-content-center"}>
              {docTypeRequired &&
                <Col xs={12} sm={5}>
                  <FormGroup >
                    <Label>{doc.docTypeLabel}</Label>
                    <Input
                      disabled={this.props.disabled}
                      value={documents[index] ? documents[index].docLabel : ""}
                      placeholder={doc.docTypePlaceHolder}
                      onChange={e => {
                        documents[index] ? documents[index].docLabel = e.target.value : documents[index] = { docLabel: e.target.value };
                        this.props.setDocumentState(this.props.docArrayName, documents);
                      }
                      }
                    />
                  </FormGroup>
                </Col>
              }
              <Col xs={12} sm={docTypeRequired || expirationDateRequired ? 4 : 8}>
                <FormGroup className={"has-label " + ((documents[index] && documents[index].s3FileName && documents[index].fileName && documents[index].fileName !== '') ? success : (this.props.user && this.props.user.applicant === true ? "" : danger))}>
                  <Label>{doc.label}</Label>
                  <br />
                  <Input
                    disabled={this.props.disabled}
                    type="text"
                    className="inputFileVisible"
                    placeholder={doc.placeHolder}
                    onClick={e => this.handleFileInput(e, index)}
                    onChange={e => e.preventDefault()}
                    value={documents[index] ? documents[index].fileName : ""}
                  />
                  <input
                    disabled={this.props.disabled}
                    type="file"
                    className="inputFileHidden"
                    style={{ zIndex: -1 }}
                    ref={doc.ref}
                    onChange={e => this.addFile(e, index, doc.label)}
                  />
                  {(documents[index] && documents[index].progress > 0 && (documents[index].progress !== 100 || this.props.showDownload === false)) &&
                    <div className="progress-container progress-primary">
                      <span className="progress-badge">Upload Progress</span>
                      <Progress max="100" value={documents[index].progress}>
                        <span className="progress-value">{documents[index].progress}%</span>
                      </Progress>
                    </div>
                  }
                  {(documents[index] && documents[index].s3FileName && this.props.showDownload !== false) &&
                    <div className="btns-mr-5">
                      <Button color="primary"
                        onClick={e => this.handleDownload(e, index)}
                        size="sm"
                      >Download</Button>
                      {(documents[index].fileName.indexOf(".jpg") !== -1 || documents[index].fileName.indexOf(".jpeg") !== -1 || documents[index].fileName.indexOf(".png") !== -1 || documents[index].fileName.indexOf(".jpg") !== -1 || documents[index].fileName.indexOf(".gif") !== -1 || documents[index].fileName.indexOf(".bmp") !== -1 || documents[index].fileName.indexOf(".pdf") !== -1) &&
                        <Button color="success"
                          onClick={e => this.handlePreview(e, index)}
                          size="sm"
                        >Preview</Button>
                      }
                    </div>
                  }
                </FormGroup>
              </Col>
              {expirationDateRequired &&
                <Col xs={12} sm={4}>
                  <FormGroup className={"has-label " + ((documents[index] && documents[index].expirationDate && documents[index].expirationDate !== '') ? success : danger)}>
                    <Label>Expiration Date</Label>
                    <Datetime
                      value={documents[index] ? documents[index].expirationDate : "" || ''}
                      timeFormat={false}
                      closeOnSelect={true}
                      dateFormat="MM/DD/YYYY"
                      utc={true}
                      inputProps={{ placeholder: "Expiration Date", disabled: this.props.disabled }}
                      onChange={(v) => {
                        let date = v instanceof moment ? v.valueOf() : "";
                        documents[index] ? documents[index].expirationDate = date : documents[index] = { expirationDate: date };
                        this.props.setDocumentState(this.props.docArrayName, documents);
                      }
                      }
                    />
                  </FormGroup>
                </Col>
              }
            </Row>
          )
        })

        }
      </>
    );

  }
}



DocUploader.defaultProps = {

};

DocUploader.propTypes = {
  docs: PropTypes.array.isRequired,
  documents: PropTypes.array.isRequired,
  user: PropTypes.object,
  disabled: PropTypes.bool,
  showDownload: PropTypes.bool,
  id: PropTypes.string.isRequired,
  rowClass: PropTypes.string,
  setDocumentState: PropTypes.func.isRequired,
  docArrayName: PropTypes.string.isRequired
};

export default DocUploader;
