/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Label,
  Button,
} from "reactstrap";
import config from "../../config";
import { API } from 'aws-amplify';


// core components
import bgImage from "assets/img/bg16.jpg";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      formValid: false

    };
  }
  componentDidMount() {
    document.body.classList.add("register-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("register-page");
  }

  checkValid(accepted) {
    const { firstName, lastName, emailValid, accept } = this.state;
    if (firstName && lastName && emailValid === true && (accepted === true || (accept === true && accepted !== false ))) {
      return true;
    } else {
      return false;
    }
  }

  
  checkboxChange = event => {
    this.setState({
      [event.target.id]: event.target.checked,
      formValid: this.checkValid(event.target.checked)
    });
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
      formValid: this.checkValid()
    });
  };

  emailChanged = event => {
    var emailRex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRex.test(event.target.value)) {
      this.setState({
        emailValid: true,
        [event.target.id]: event.target.value,
        formValid: this.checkValid()
      });
    } else {
      this.setState({
        emailValid: false,
        [event.target.id]: event.target.value,
        formValid: false
      });

    }
  }


  handleSubmit = async event => {

    if (event) {
      event.preventDefault();
    }

    console.log("Email = " + this.state.email.toLowerCase());

    // Form validation
    // this.clearErrorState();


    let email = this.state.email ? this.state.email.toLowerCase() : "";

    let apiName = config.api.API_NAME;
    let path = '/users/register';
    let myInit = { // OPTIONAL
      headers: {
      }, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: email
      }
    }
    console.log("myInit = " + JSON.stringify(myInit));
    API.post(apiName, path, myInit).then(response => {
      console.log("Respone : " + JSON.stringify(response));
    }).catch(error => {
      console.log(error);
    });
  };

  render() {
    return (
      <>
        <div className="content">
          <div className="register-page">
            <Container>
              <Row className="justify-content-center">
                <Col lg={5} md={8} xs={12}>
                  <div className="info-area info-horizontal mt-5">
                    <div className="icon icon-primary">
                      <i className="now-ui-icons media-2_sound-wave" />
                    </div>
                    <div className="description">
                      <h5 className="info-title">Compliance</h5>
                      <p className="description">
                        Compliance is extremely important, proper auditing and traceability
                        is at the center of Compliance.
                      </p>
                    </div>
                  </div>
                  <div className="info-area info-horizontal">
                    <div className="icon icon-primary">
                      <i className="now-ui-icons media-1_button-pause" />
                    </div>
                    <div className="description">
                      <h5 className="info-title">Training</h5>
                      <p className="description">
                        We are dedicated to training and certifications, our missing is to make
                        this as low friction and as easy as possible.
                      </p>
                    </div>
                  </div>
                  <div className="info-area info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons users_single-02" />
                    </div>
                    <div className="description">
                      <h5 className="info-title">Support</h5>
                      <p className="description">
                        If you run into any problems we are always there to
                        help and support you.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={8} xs={12}>
                  <Card className="card-signup">
                    <CardHeader className="text-center">
                      <CardTitle tag="h4">Register</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <InputGroup
                          className={
                            this.state.firstnameFocus ? "input-group-focus" : ""
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons users_circle-08" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder="First Name..."
                            id="firstName"
                            value={this.state.firstName}
                            onChange={this.onInputChange}
                            onFocus={(e) =>
                              this.setState({ firstnameFocus: true })
                            }
                            onBlur={(e) =>
                              this.setState({ firstnameFocus: false })
                            }
                          />
                        </InputGroup>
                        <InputGroup
                          className={
                            this.state.lastnameFocus ? "input-group-focus" : ""
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons text_caps-small" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            id="lastName"
                            placeholder="Last Name..."
                            value={this.state.lastName}
                            onChange={this.onInputChange}
                            onFocus={(e) =>
                              this.setState({ lastnameFocus: true })
                            }
                            onBlur={(e) =>
                              this.setState({ lastnameFocus: false })
                            }
                          />
                        </InputGroup>
                        <InputGroup
                          className={
                            this.state.emailFocus ? "input-group-focus" : ""
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="email"
                            placeholder="Email..."
                            id="email"
                            onChange={this.emailChanged}
                            value={this.state.password}
                            onFocus={(e) => this.setState({ emailFocus: true })}
                            onBlur={(e) => this.setState({ emailFocus: false })}
                          />
                        </InputGroup>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              id="accept"
                              value={this.state.accept}
                              onChange={this.checkboxChange}
                            />
                            <span className="form-check-sign" />
                            <div>
                              I agree to the{" "}
                              <a href="#something">terms and conditions</a>.
                            </div>
                          </Label>
                        </FormGroup>
                      </Form>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        color="primary"
                        size="lg"
                        className="btn-round"
                        onClick={this.handleSubmit}
                        disabled={!this.state.formValid}
                      >
                        Register
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundImage: "url(" + bgImage + ")" }}
        />
      </>
    );
  }
}

export default RegisterPage;
