/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button,
    UncontrolledTooltip
} from "reactstrap";
import { Auth, API } from 'aws-amplify';
import config from "../../config";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import EditCertificateModal from "./EditCertificateModal.js";
import { listData } from "util/ComponentUtil";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";


class ListCertificates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editModal: false,
            data: [],
            alert: null

        };
        this.updateTable = this.updateTable.bind(this);
    }

    toggleEditModal = (newCertificate, certificate) => {
        console.log(certificate);
        this.setState({
            editModal: !this.state.editModal,
            newCertificate: newCertificate,
            certificate: certificate
        });
    };

    updateTable(certificate) {
        let data = Object.assign([], this.state.data);
        let certificates = Object.assign([], this.state.certificates);

        let origLen = data.length;
        data = data.filter((o) => o.id !== certificate.id);

        if (data.length === origLen) {
            //this is not an existing course
            certificates.push(certificate);
            this.setState({
                certificates: certificates
            });
        }




        data.push(this.createTableRow(certificate, data.length + 1));

        this.setState({
            data: data
        });
    }

    async deleteCert(id) {
        console.log("deleteCertificate");

        const user = await Auth.currentAuthenticatedUser();
        const jwtToken = user.signInUserSession.idToken.jwtToken;

        // console.log(jwtToken);

        let apiName = config.api.API_NAME;
        let path = '/certificates/' + encodeURIComponent(id);
        let myInit = { // OPTIONAL
            headers: {
                Authorization: "Bearer " + jwtToken
            }, // OPTIONAL
            response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            // queryStringParameters: {  // OPTIONAL
            //     id: certificate.sk
            // }
        }
        API.del(apiName, path, myInit).then(response => {
            console.log("Respone : " + JSON.stringify(response));



        }).catch(error => {
            console.log(error);
        });
    }

    deleteCertificate(certificateSk, certificateId) {

        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => {
                        let data = Object.assign([], this.state.data);
                        this.deleteCert(certificateSk);
                        data = data.filter((o) => o.id !== certificateId);
                        this.setState({ data: data });
                        this.hideAlert();
                    }}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, delete!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    This will Delete the certificate.
                </SweetAlert>
            )
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    createTableRow(prop, key) {
        // console.log("prop._ct == " + prop._ct);

        let emp = this.state.employees ? this.state.employees.find((o) => o.id === prop.employeeId) : null;
        // console.log("emp " + JSON.stringify(emp));
        let entityName = "";
        if (emp) {
            entityName = emp.firstName + " " + emp.lastName;
        } else {
            let eqp = this.state.equipments.find((o) => o.id === prop.equipmentId);
            if (eqp) {
                entityName = eqp.name;
            }
        }

        return {
            id: prop.id,
            name: prop.name,
            entityName: entityName,
            certType: prop.certType,
            status: prop.status && prop.status.label ? prop.status.label : prop.status,
            dateTime: prop.expiryDate ? moment(parseInt(prop.expiryDate)).format("DD MMM YYYY") : "",
            dateCreated: moment(parseInt(prop._ct * 1000)).format("DD MMM YYYY"),
            actions: (
                // we've added some custom button actions
                <div className="actions-right">
                    {/* use this button to add a like kind of action */}
                    <Button
                        onClick={() => {
                            // let obj = this.state.data.find((o) => o.id === key);
                            // console.log("This is the certificate to edit : " + JSON.stringify(obj));
                            this.toggleEditModal(false, prop);
                        }}
                        className="btn-icon btn-round"
                        color="warning"
                        size="sm"
                        id={"cert" + key}
                    >
                        <i className="fa fa-edit" />
                    </Button>
                    <UncontrolledTooltip
                        delay={0}
                        target={"cert" + key}
                        trigger={"hover"}
                    >
                        Edit Certificate
                    </UncontrolledTooltip>{" "}
                    {/* use this button to remove the data row */}
                    <Button
                        disabled={false}
                        onClick={() => this.deleteCertificate(prop.sk, prop.id)}
                        className="btn-icon btn-round"
                        color="danger"
                        size="sm"
                        id={"delete" + key}
                    >
                        <i className="fa fa-times" />
                    </Button>
                    <UncontrolledTooltip
                        delay={0}
                        trigger={"hover"}
                        target={"delete" + key}
                    >
                        Delete
                    </UncontrolledTooltip>{" "}
                </div>
            ),
        };
    }

    async componentDidMount() {
        console.log("ComponentDidMount");

        const user = await Auth.currentAuthenticatedUser();
        const jwtToken = user.signInUserSession.idToken.jwtToken;

        // console.log(jwtToken);

        const employees = await listData("/employees");
        const equipments = await listData("/equipments");


        let apiName = config.api.API_NAME;
        let path = '/certificates';
        let myInit = { // OPTIONAL
            headers: {
                Authorization: "Bearer " + jwtToken
            }, // OPTIONAL
            response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {  // OPTIONAL
                id: 123
            }
        }
        API.get(apiName, path, myInit).then(response => {
            console.log("Respone : " + JSON.stringify(response));
            this.setState({
                employees: employees,
                equipments: equipments
            });

            let data = response.map((prop, key) => this.createTableRow(prop, key));
            // console.log(JSON.stringify(data));
            this.setState({
                data: data,
                certificates: response,
                employees: employees,
                equipments: equipments
            });
        }).catch(error => {
            console.log(error);
        });


    }

    render() {
        return (
            <>
                <EditCertificateModal
                    updateTable={this.updateTable}
                    isOpen={this.state.editModal}
                    toggle={this.toggleEditModal}
                    newCertificate={this.state.newCertificate}
                    certificate={this.state.certificate}
                    user={this.props.auth.user} />
                <PanelHeader
                    size="sm"
                    content={
                        <div className="header text-center">
                            {/* <h2 className="title">Certificates</h2> */}
                            {/* <p className="category">
                A powerful react plugin handcrafted by our friends from{" "}
                <a
                  href="https://react-table.js.org/#/story/readme"
                  target="_blank"
                >
                  react-table
                </a>
                . It is a highly flexible tool, based upon the foundations of
                progressive enhancement on which you can add advanced
                interaction controls. Please check out their{" "}
                <a
                  href="https://react-table.js.org/#/story/readme"
                  target="_blank"
                >
                  full documentation.
                </a>
              </p> */}
                        </div>
                    }
                />
                <div className="content">
                    {this.state.alert}
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Certificates
                                        {/* <Button color="info" className="btn-round float-right" size="sm"
                                            onClick={() => this.toggleEditModal(true, null)}
                                        >
                                            <i className="now-ui-icons ui-2_favourite-28" /> New Certificate
                                        </Button> */}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.state.data}
                                        columns={[
                                            {
                                                Header: "Certificate Name",
                                                accessor: "name",
                                            },
                                            {
                                                Header: "Cert Type",
                                                accessor: "certType",
                                            },
                                            {
                                                Header: "Name",
                                                accessor: "entityName",
                                            },
                                            {
                                                Header: "Expiry Date",
                                                accessor: "dateTime",
                                            },
                                            {
                                                Header: "Created Date",
                                                accessor: "dateCreated",
                                            },
                                            {
                                                Header: "Actions",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                            },
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default ListCertificates;
