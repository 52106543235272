/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ListSystemTenants from "pages/organisation/ListSystemTenants";


let routes = [
  {
    collapse: true,
    path: "/admin",
    name: "System",
    state: "openOrganisation",
    icon: "now-ui-icons design-2_ruler-pencil",
    views: [
      {
        path: "/list-sysTenants",
        name: "List System Tenants",
        mini: "LS",
        component: ListSystemTenants,
        layout: "/admin",
      }
    ]
  }
];

export default routes;
