/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import {
    Button,
    UncontrolledTooltip
} from "reactstrap";
import moment from "moment";
import EditEmployeeModal from "./EditEmployeeModal.js";
import ListCommon from "./ListCommon";


// core components
import ListBase from "./ListBase";
import ListCertificatesModal from "./ListCertificatesModal.js";

const tableColumns = [
    {
        Header: "ID Number",
        accessor: "internalId",
    },
    {
        Header: "Name",
        accessor: "name",
    },
    {
        Header: "Created Date",
        accessor: "dateCreated",
    },
    {
        Header: "Actions",
        accessor: "actions",
        sortable: false,
        filterable: false,
    },
];

class ListEmployees extends ListCommon {
    constructor(props) {
        super(props, { domainObject: "employee", listCertModal: false });
    }

    toggleListCertModal = () => {
        console.log("toggleListCertModal");
        this.setState({
            listCertModal: !this.state.listCertModal
        });
    };

    setSelectedEmployee = (employeeId) => {
        console.log("setSelectedEmployee employeeId : " + JSON.stringify(employeeId));
        this.setState({
            listCertModal: !this.state.listCertModal,
            selectedEmployeeId: employeeId
        });
    };



    createTableRow(prop, key) {
        console.log("prop._ct == " + prop._ct);
        return {
            id: prop.id,
            name: prop.firstName + ' ' + prop.lastName,
            internalId: prop.internalId,
            status: prop.status && prop.status.label ? prop.status.label : prop.status,
            dateTime: moment(parseInt(prop.expiryDate)).format("DD MMM YYYY"),
            dateCreated: moment(parseInt(prop._ct * 1000)).format("DD MMM YYYY"),
            actions: (
                // we've added some custom button actions
                <div className="actions-right">
                    {/* use this button to add a like kind of action */}
                    <Button
                        onClick={() => {
                            // let obj = this.state.data.find((o) => o.id === key);
                            // console.log("This is the certificate to edit : " + JSON.stringify(obj));
                            this.setSelectedEmployee(prop.id);
                        }}
                        className="btn-icon btn-round"
                        color="warning"
                        size="sm"
                        id={"cert"+key}
                    >
                        <i className="fa fa-camera-retro" />
                    </Button>
                    <UncontrolledTooltip
                        delay={0}
                        target={"cert"+key}
                        trigger={"hover"}
                    >
                        Certificates
                    </UncontrolledTooltip>{" "}
                    <Button
                        onClick={() => {
                            // let obj = this.state.data.find((o) => o.id === key);
                            // console.log("This is the certificate to edit : " + JSON.stringify(obj));
                            this.toggleEditModal(false, prop);
                        }}
                        className="btn-icon btn-round"
                        color="warning"
                        size="sm"
                        id={"edit"+key}
                    >
                        <i className="fa fa-edit" />
                    </Button>
                    <UncontrolledTooltip
                        delay={0}
                        target={"edit"+key}
                        trigger={"hover"}
                    >
                        Edit
                    </UncontrolledTooltip>{" "}
                    {/* use this button to remove the data row */}
                    <Button
                        disabled={false}
                        onClick={() => this.deleteRecord(prop.sk, prop.id)}
                        className="btn-icon btn-round"
                        color="danger"
                        size="sm"
                        id={"delete"+key}
                    >
                        <i className="fa fa-times" />
                    </Button>
                    <UncontrolledTooltip
                        delay={0}
                        trigger={"hover"}
                        target={"delete"+key}
                    >
                        Delete
                    </UncontrolledTooltip>{" "}
                </div>
            ),
        };
    }


    render() {
        return (
            <>
                {this.state.alert}
                <ListCertificatesModal
                    auth={this.props.auth}
                    isOpen={this.state.listCertModal}
                    toggle={this.toggleListCertModal}
                    employeeId={this.state.selectedEmployeeId}
                />
                <EditEmployeeModal
                    updateTable={this.updateTable}
                    isOpen={this.state.editModal}
                    toggle={this.toggleEditModal}
                    newRecord={this.state.newRecord}
                    record={this.state.record}
                    user={this.props.auth.user}
                    openCertificates={this.setSelectedEmployee}
                />
                <ListBase title="Employees"
                    tableColumns={tableColumns}
                    createTableRow={this.createTableRow}
                    data={this.state.data}
                    toggleEditModal={this.toggleEditModal}
                >

                </ListBase>
            </>
        );
    }
}

export default ListEmployees;
