/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";


class ListBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editModal: false,
            data: [],
            alert: null

        };
    }




    render() {
        return (
            <>
                <PanelHeader
                    size="sm"
                    content={
                        <div className="header text-center">
                            {/* <h2 className="title">Certificates</h2> */}
                            {/* <p className="category">
                A powerful react plugin handcrafted by our friends from{" "}
                <a
                  href="https://react-table.js.org/#/story/readme"
                  target="_blank"
                >
                  react-table
                </a>
                . It is a highly flexible tool, based upon the foundations of
                progressive enhancement on which you can add advanced
                interaction controls. Please check out their{" "}
                <a
                  href="https://react-table.js.org/#/story/readme"
                  target="_blank"
                >
                  full documentation.
                </a>
              </p> */}
                        </div>
                    }
                />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{this.props.title}
                                        <Button color="info" className="btn-round float-right" size="sm"
                                            onClick={() => this.props.toggleEditModal(true, null)}
                                        >
                                            <i className="now-ui-icons ui-2_favourite-28" /> New {this.props.title.endsWith("s") ? this.props.title.slice(0, -1) : this.props.title}
                                        </Button>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.props.data}
                                        columns={this.props.tableColumns}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default ListBase;
