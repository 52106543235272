
import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import throttle from "lodash.throttle"
import { handleDownloadByFullPath } from "../util/FileUtil";

import {
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


class DisplayPDFModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      pageNumber: 1
    };

    this.throttledSetDivSize = throttle(this.setDivSize, 500);
  }



  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  goToPrevPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
  goToNextPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber + 1 }));

  componentWillUnmount() {
    window.removeEventListener("resize", this.throttledSetDivSize);
  }

  setDivSize = () => {
    this.setState({ width: this.pdfWrapper.getBoundingClientRect().width - 150 })
  }


  async componentDidMount() {
    this.setDivSize();
    window.addEventListener("resize", this.throttledSetDivSize);
  }


  render() {
    const { pageNumber, numPages, width } = this.state;

    let nav =
      <nav>
        <button disabled={numPages === 1 || pageNumber === 1} onClick={this.goToPrevPage}>Prev</button>{' '}
        <button disabled={numPages === 1 || pageNumber === numPages} onClick={this.goToNextPage}>Next</button>{' '}
        <button color="primary"
          onClick={e => handleDownloadByFullPath(e, this.props.fileName)}
          size="sm"
        >Download</button>
      </nav>;


    return (
      <div id="pdfWrapper" ref={(ref) => this.pdfWrapper = ref}>
        <Modal size="lg" isOpen={this.props.isOpen} toggle={this.props.toggle}>
          <ModalHeader toggle={this.props.toggle}>Document</ModalHeader>
          <ModalBody>

            <div>
              <nav>
                {nav}
              </nav>


              {this.props.pdfData &&
                <Document
                  file={this.props.pdfData}
                  onLoadError={console.error}
                  onLoadSuccess={this.onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} width={width} />
                </Document>
              }
            

            <p>
              Page {pageNumber} of {numPages}
            </p>

            <nav>
              {nav}
            </nav>

          </div>
        </ModalBody>
      </Modal>
      </div >
    );
  }
}


export default DisplayPDFModal;