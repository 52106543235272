/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    ButtonGroup,
    Row,
    Col,
    Label,
    Form,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    FormGroup,
    Input
} from "reactstrap";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import moment from "moment";
// react plugin used to create datetimepicker
import ReactTable from "components/ReactTable/ReactTable.js";
import CourseDTModal from "./CourseDTModal"
import { Auth, API } from 'aws-amplify';
import config from "../../config";
import InputMask from 'react-input-mask';


const dataTable = [
    [1, 1601675920797],
    [2, 1601335920797]
];


var statusOptions = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" }
];


var locationOptions = [
    { value: "Dublin", label: "Dublin" },
    { value: "Clare", label: "Clare" },
    { value: "Galway", label: "Galway" },
    { value: "Meath", label: "Meath" },
    { value: "Wicklow", label: "Wicklow" }
];

const initCourse = {
    title: "",
    description: "",
    status: {
        label: "Active",
        value: "Active"
      },
    daTimes: null,
    cost: 0
};

class EditCourseModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseDTModal: false,
            course: initCourse,
            data: [],
            valid: false,
            register: {
                emailState: "",
                passwordState: "",
                confirmState: "",
                fullNameState: "",
                email: "",
                password: "",
                confirm: "",
                fullName: "",
            },
            type: {
                required: "",
                email: "",
                number: "",
                url: "",
                source: "",
                destionation: "",
                requiredState: "",
                emailState: "",
                numberState: "",
                urlState: "",
                equalState: "",
            }
        };

        this.valueChange = this.valueChange.bind(this);
        this.save = this.save.bind(this);
        this.noParticipantsChange = this.noParticipantsChange.bind(this);
        this.costChange = this.costChange.bind(this);
    }

    toggleCourseDTModal = () => {
        this.setState({
            courseDTModal: !this.state.courseDTModal
        });
    };

    addSchedule = (scheduleDate, scheduleTime, noParticipants) => {
        console.log("Add schedule : " + scheduleDate + " scheduleTime : " + scheduleTime) + " noParticipants : " + noParticipants;
        let course = Object.assign({}, this.state.course);

        let dateTime = scheduleDate + ":" + scheduleTime;
        const key = moment(dateTime, 'DD/MM/YYYY:HH:mm').valueOf();

        //console.log("DatTimes = "+JSON.stringify(course.daTimes));
        course.daTimes = course.daTimes ? course.daTimes : {};
        course.daTimes[key] = {
            "noParticipants": noParticipants,
            "noFilled": noParticipants,
            "newDT": true
        };



        this.setState({
            data: Object.entries(course.daTimes).map(([dt, obj]) => {
                return this.createTableRow(obj, dt);
            }),
            course: course
        }, this.checkValid(course));
        this.toggleCourseDTModal();
    };

    registerEmail(e) {
        var register = this.state.register;
        register["email"] = e.target.value;
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(e.target.value)) {
            register["emailState"] = "has-success";
        } else {
            register["emailState"] = "has-danger";
        }
        this.setState({ register });
    }

    registerSubmit(e) {
        var register = this.state.register;
        if (register["emailState"] !== "has-success")
            register["emailState"] = "has-danger";
        if (register["passwordState"] !== "has-success")
            register["passwordState"] = "has-danger";
        if (register["confirmState"] !== "has-success")
            register["confirmState"] = "has-danger";
        this.setState({ register });
    }

    async save(e) {
        console.log("Save Clicked");
        const user = await Auth.currentAuthenticatedUser();
        const jwtToken = user.signInUserSession.idToken.jwtToken;

        // console.log(jwtToken);

        let apiName = config.api.API_NAME;
        let path = '/courses';
        let myInit = { // OPTIONAL
            headers: {
                Authorization: "Bearer " + jwtToken
            }, // OPTIONAL
            response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            body: this.state.course
        }
        // console.log("myInit = " + JSON.stringify(myInit));
        API.post(apiName, path, myInit).then(response => {
            //console.log("Respone : " + JSON.stringify(response));
            this.props.updateTable(response);
            this.props.toggle();

        }).catch(error => {
            console.log(error);
        });

    }

    typeRequired(e) {
        var type = this.state.type;
        type["required"] = e.target.value;
        if (type["required"].length > 0) {
            type["requiredState"] = "has-success";
        } else {
            type["requiredState"] = "has-danger";
        }
        this.setState({ type });
        // console.log(JSON.stringify(type));
    }


    valueChange(e) {
        let course = Object.assign({}, this.state.course);
        course[e.target.name] = e.target.value;
        this.setState({
            course: course
        }, this.checkValid(course));
    }


    checkValid(course) {
        let { title, description, daTimes } = course;

        let valid = true;
        if (!title || !description || !daTimes || Object.keys(daTimes).length < 1) {
          valid = false;
        }
        this.setState({ valid: valid });
      }

    noParticipantsChange(e) {
        let course = Object.assign({}, this.state.course);
        course[e.target.name] = e.target.value.replace(/\D/, '');
        this.setState({
            course: course
        });
    }

    costChange(e) {
        let course = Object.assign({}, this.state.course);
        course[e.target.name] = e.target.value.replace(/\D/, '');
        this.setState({
            course: course
        });
    }
    createTableRow(prop, key) {
        // console.log("Key: '" + key + "'");
        // console.log(JSON.stringify(prop));
        return {
            id: key,
            dateTime: moment(parseInt(key)).format("DD MMM YYYY hh:mm a"),
            noFilled: (prop.noParticipants - (prop.noFilled ? prop.noFilled : 0)),
            noParticipants: prop.noParticipants,
            actions: (
                // we've added some custom button actions
                <div className="actions-right">
                    {/* use this button to add a like kind of action */}

                    {/* use this button to add a edit kind of action */}
                    <Button
                        disabled={true}
                        onClick={() => {
                            let obj = this.state.data.find((o) => o.id === key);
                            alert(
                                "You've clicked EDIT button on \n{ \nName: " +
                                obj.name +
                                ", \nstatus: " +
                                obj.status +
                                ", \nlocation: " +
                                obj.location +
                                "\n}."
                            );
                            this.toggleEditModal(false);
                        }}
                        className="btn-icon btn-round"
                        color="warning"
                        size="sm"
                    >
                        <i className="fa fa-edit" />
                    </Button>{" "}
                    {/* use this button to remove the data row */}
                    <Button
                        disabled={true}
                        onClick={() => {
                            var data = this.state.data;
                            data.find((o, i) => {
                                if (o.id === key) {
                                    // here you should add some custom code so you can delete the data
                                    // from this component and from your server as well
                                    data.splice(i, 1);
                                    // console.log(data);
                                    return true;
                                }
                                return false;
                            });
                            this.setState({ data: data });
                        }}
                        className="btn-icon btn-round"
                        color="danger"
                        size="sm"
                    >
                        <i className="fa fa-times" />
                    </Button>{" "}
                </div>
            ),
        };
    }


    async componentDidMount() {
        // console.log("ComponentDidMount " + JSON.stringify(this.props.course));
    }

    componentDidUpdate(prevProps) {
        const { course, newCourse } = this.props;
        // console.log("NewCOurse = "+newCourse);
        // console.log("prevProps.newCourse = "+prevProps.newCourse);
        // console.log("ComponentDidUpdate " + JSON.stringify(this.state.course));
        // console.log("data : "+JSON.stringify(this.state.data));
        // if (this.props.app && this.props.app.application && this.props.app.application.id !== (prevProps.app ? prevProps.app.application ? prevProps.app.application.id : null : null)) {
        if ((course && !prevProps.course) || (course && prevProps.course && prevProps.course.id !== course.id)) {
            this.setState({
                course: this.props.newCourse === false ? course : initCourse,
                data: this.props.newCourse === false ? Object.entries(course.daTimes).map(([dt, obj]) => {
                    return this.createTableRow(obj, dt);
                }) : []
            });
        } else if (newCourse === true && prevProps.newCourse !== newCourse) {
            this.setState({
                course: initCourse,
                data: []
            });
        }
    }


    render() {
        return (
            <>
                <CourseDTModal isOpen={this.state.courseDTModal} toggle={this.toggleCourseDTModal} course={this.state.course} addSchedule={this.addSchedule} />
                <Modal style={{ maxWidth: '1000px', width: '95%', margin: '10px auto' }}
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}

                >
                    <ModalHeader
                        className="justify-content-center uppercase title"
                        toggle={this.props.toggle}
                        tag="h4"
                    >
                        {this.props.newCourse === true ? "New Course" : "Edit Course"}
                    </ModalHeader>
                    <ModalBody>
                        <div className="content">
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form className="form-horizontal">
                                        <Card>
                                            <CardHeader>
                                                <CardTitle tag="h4">Course Details</CardTitle>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Label sm={2}>Course title</Label>
                                                    <Col xs={12} sm={7}>
                                                        <FormGroup className={this.state.type.requiredState}>
                                                            <Input
                                                                value={this.state.course.title}
                                                                type="required"
                                                                onChange={this.valueChange}
                                                                name="title"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Label sm={3} className="label-on-right">
                                                        <code>*</code>
                                                    </Label>
                                                </Row>
                                                {/* <Row>
                                                    <Label sm={2}>Status</Label>
                                                    <Col xs={12} sm={7}>
                                                        <FormGroup className={this.state.type.requiredState}>
                                                            <Select
                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                placeholder="Status"
                                                                name="singleSelect"
                                                                value={this.state.course.status}
                                                                options={statusOptions}
                                                                onChange={(value) => {
                                                                    let course = Object.assign({}, this.state.course);
                                                                    course['status'] = value;
                                                                    this.setState({
                                                                        course: course
                                                                    });
                                                                }
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Label sm={3} className="label-on-right">
                                                        <code>*</code>
                                                    </Label>
                                                </Row> */}
                                                {/* <Row>
                                                    <Label sm={2}>Location</Label>
                                                    <Col xs={12} sm={7}>
                                                        <FormGroup className={this.state.type.requiredState}>
                                                            <Select
                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                placeholder="Location"
                                                                name="singleSelect"
                                                                value={this.state.course.location}
                                                                options={locationOptions}
                                                                onChange={(value) => {
                                                                    let course = Object.assign({}, this.state.course);
                                                                    course['location'] = value;
                                                                    this.setState({
                                                                        course: course
                                                                    });
                                                                }
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Label sm={3} className="label-on-right">
                                                        <code>*</code>
                                                    </Label>
                                                </Row> */}
                                                {/* <Row>
                                                    <Label sm={2}>Cost per Participant</Label>
                                                    <Col xs={12} sm={7}>
                                                        <FormGroup className={this.state.type.requiredState}>
                                                            <Input
                                                                value={this.state.course.cost}
                                                                onChange={this.costChange}
                                                                name="cost"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Label sm={3} className="label-on-right">
                                                        <code>*</code>
                                                    </Label>
                                                </Row> */}
                                                <Row>
                                                    <Label sm={2}>Description</Label>
                                                    <Col xs={12} sm={7}>
                                                        <FormGroup className={this.state.type.requiredState}>
                                                            <Input
                                                                type="textarea"
                                                                style={{ maxHeight: 150 + 'px' }}
                                                                rows="15"
                                                                value={this.state.course.description}
                                                                onChange={this.valueChange}
                                                                name="description"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Label sm={3} className="label-on-right">
                                                        <code>*</code>
                                                    </Label>
                                                </Row>
                                                <Row>
                                                    <Label sm={2}>Dates & Times</Label>
                                                    <Col xs={12} sm={7}>
                                                        <FormGroup className={this.state.type.requiredState}>
                                                            <Button color="default"
                                                                onClick={() => {
                                                                    this.toggleCourseDTModal();
                                                                }}
                                                            >Add</Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Label sm={2}></Label>
                                                    <Col xs={10} sm={10}>
                                                        <ReactTable
                                                            showPagination={false}
                                                            showFilter={false}
                                                            data={this.state.data}

                                                            columns={[
                                                                {
                                                                    Header: "Date & Time",
                                                                    accessor: "dateTime",
                                                                    sortable: true,
                                                                    // defaultSorted: true,
                                                                    filterable: false,
                                                                    //sortType: "datetime"
                                                                },
                                                                {
                                                                    Header: "No. Participants",
                                                                    accessor: "noParticipants",
                                                                    sortable: false,
                                                                    filterable: false,
                                                                },
                                                                {
                                                                    Header: "No. Filled",
                                                                    accessor: "noFilled",
                                                                    sortable: false,
                                                                    filterable: false,
                                                                },
                                                                {
                                                                    Header: "Actions",
                                                                    accessor: "actions",
                                                                    sortable: false,
                                                                    filterable: false,
                                                                }
                                                            ]}
                                                            defaultSorted={[
                                                                {
                                                                    id: 'dateTime',
                                                                    desc: false
                                                                }
                                                            ]}
                                                        />
                                                    </Col>


                                                </Row>
                                            </CardBody>
                                            <CardFooter className="text-left">


                                            </CardFooter>
                                        </Card>
                                    </Form>
                                </Col>
                            </Row>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={!this.state.valid} color="success"
                            onClick={this.save} >Save</Button>
                        <Button
                            color="danger"
                            onClick={this.props.toggle}
                        >
                            Close
                          </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default EditCourseModal;
