/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
    Row,
    Col,
    Label,
    FormGroup,
    Input
} from "reactstrap";

import EditModalCommon from './EditModalCommon';
import EditModalBase from './EditModalBase';
import { listData } from "../../util/ListPageUtil";
import Select from "react-select";
import EditCertificate from "../courses/EditCertificate";
import Switch from "react-bootstrap-switch";


const initEquipment = {
    internalId: "",
    name: "",
    locationId: ""
};

class EditEquipmentModal extends EditModalCommon {
    constructor(props) {
        super(props, {
            domainObject: "equipment",
            equipment: initEquipment,
            locSelect: []
        }, initEquipment
        );

        this.checkValid = this.checkValid.bind(this);
    }

    checkValid(equipment) {
        console.log("checkValid " + JSON.stringify(equipment));
        let { internalId, name, locationId } = equipment;

        let valid = true;
        if (!internalId || !name) {
            valid = false;
        }
        return valid;
    }

    checkboxChange = event => {
        this.setState({
            addCertificates: true
        });
    };

    async componentDidMount() {
        console.log("ComponentDidMount " + this.state.domainObject);

        const locations = await listData("/locations");

        const locSelect = locations.map(l => {
            let obj = {};
            obj.label = l.name;
            obj.value = l.id;
            return obj;
        });

        let state = Object.assign({}, this.state);

        state.locSelect = locSelect;
        state.initRecord.locationId = locSelect.length === 1 ? locSelect[0].value : "";

        this.setState(state);
    }


    render() {
        return (
            <>
                <EditModalBase save={this.save}
                    addHeader={true}
                    valid={this.state.valid}
                    domainObject="equipment"
                    modalType="Equipment"
                    updateTable={this.props.updateTable}
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    newRecord={this.props.newRecord}
                    record={this.props.record}
                    initRecord={this.initEquipment}>
                    <Row>
                        <Label sm={2}>Identification Number</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Input
                                    value={this.state.equipment.internalId}
                                    type="required"
                                    onChange={this.valueChange}
                                    name="internalId"
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row>
                    <Row>
                        <Label sm={2}>Name</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Input
                                    value={this.state.equipment.name}
                                    type="required"
                                    onChange={this.valueChange}
                                    name="name"
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row>
                    <Row>
                        <Col xs={12} sm={7}>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        id="accept"
                                        value={this.state.accept}
                                        onChange={() => this.setState({ addCertificates: true })}
                                    />
                                    <span className="form-check-sign" />
                                    <div>
                                        Add Certificates?
                                    </div>
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Label sm={2}>Location</Label>
                        <Col xs={12} sm={7}>
                            <FormGroup className={this.state.type.requiredState}>
                                <Select
                                    className="primary react-select"
                                    classNamePrefix="react-select"
                                    placeholder="Please select"
                                    value={this.state.locSelect.find((o) => o.value === this.state.equipment.locationId)}
                                    options={this.state.locSelect}                                    
                                    onChange={(value) => this.valueChangeBase("locationId", value.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Label sm={3} className="label-on-right">
                            <code>*</code>
                        </Label>
                    </Row> */}
                    {/* <EditCertificate
                        ref={instance => { this.child = instance; }}
                        updateTable={this.props.updateTable}
                        newCertificate={this.props.newCertificate}
                        certificate={this.props.certificate}
                        isOpen={this.props.isOpen}
                        toggle={this.props.toggle}
                        user={this.props.user}
                        childComp={true}
                    /> */}

                </EditModalBase>

            </>
        );
    }
}

export default EditEquipmentModal;