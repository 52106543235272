/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap";
import { Auth, API } from 'aws-amplify';
import config from "../../config";
import moment from "moment";

import EditCertificateModal from "./EditCertificateModal.js";



import EditCourseModal from "./EditCourseModal.js";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";


class ListBookings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editModal: false,
            data: []

        };
        this.updateTable = this.updateTable.bind(this);
    }

    toggleEditModal = (newCourse) => {
        this.setState({
            editModal: !this.state.editModal,
            newCourse: newCourse
        });
    };

    updateTable(course) {
        let data = Object.assign([], this.state.data);
        data.push(this.createTableRow(course, data.length + 1));
        this.setState({
            data: data
        });
    }

    async deleteCourse(id) {
        console.log("deleteCourse");

        const user = await Auth.currentAuthenticatedUser();
        const jwtToken = user.signInUserSession.idToken.jwtToken;

        console.log(jwtToken);

        let apiName = config.api.API_NAME;
        let path = '/courses/' + encodeURIComponent(id);
        let myInit = { // OPTIONAL
            headers: {
                Authorization: "Bearer " + jwtToken
            }, // OPTIONAL
            response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            // queryStringParameters: {  // OPTIONAL
            //     id: course.sk
            // }
        }
        API.del(apiName, path, myInit).then(response => {
            console.log("Respone : " + JSON.stringify(response));



        }).catch(error => {
            console.log(error);
        });
    }

    createTableRow(prop, key) {

        return {
            id: key,
            name: prop.firstName + " " + prop.lastName,
            email: prop.email,
            noPart: prop.noPart,
            phone: prop.phone,
            totalCost: prop.totalCost,
            courseTitle: prop.courseTitle,
            status: "Booked",
            courseDate: moment(parseInt(prop.dateTime)).format("DD MMM YYYY hh:mm a"),
            dateCreated: moment(parseInt(prop._ct * 1000)).format("DD MMM YYYY"),
            actions: (
                // we've added some custom button actions
                <div className="actions-right">
                    {/* use this button to add a like kind of action */}

                    {/* use this button to add a edit kind of action */}
                    <Button
                        disabled={false}
                        onClick={async () => {
                            const user = await Auth.currentAuthenticatedUser();
                            const jwtToken = user.signInUserSession.idToken.jwtToken;

                            console.log(jwtToken);
                            console.log(JSON.stringify(prop));

                            let apiName = config.api.API_NAME;
                            let path = '/bookings/' + encodeURIComponent(prop.sk) + "/certificate";
                            let myInit = { // OPTIONAL
                                headers: {
                                    Authorization: "Bearer " + jwtToken
                                }, // OPTIONAL
                                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
                                queryStringParameters: {  // OPTIONAL
                                    // id: 123
                                }
                            }
                            API.get(apiName, path, myInit).then(response => {
                                console.log("Respone : " + JSON.stringify(response));
                                //console.log(JSON.stringify(prop));
                                this.setState({
                                    booking: prop,
                                    certificate: response,
                                    newCertificate: false
                                });
                                this.toggleEditModal(false);
                                // this.setState({
                                //     data: response.map((prop, key) => {
                                //         return this.createTableRow(prop, key);
                                //     })

                                // });
                            }).catch(error => {
                                if(error.response && error.response.status === 404) {
                                    this.setState({
                                        booking: prop,
                                        newCertificate: true
                                    });
                                    this.toggleEditModal(false);
                                }
                                console.log(error);
                            });                            
                        }}
                        className="btn-icon btn-round"
                        color="success"
                        size="sm"
                    >
                        <i className="fa fa-camera-retro" />
                    </Button>{" "}

                    <Button
                        disabled={true}
                        onClick={() => {
                            let obj = this.state.data.find((o) => o.id === key);
                            alert(
                                "You've clicked EDIT button on \n{ \nName: " +
                                obj.name +
                                ", \nstatus: " +
                                obj.status +
                                ", \nlocation: " +
                                obj.location +
                                "\n}."
                            );
                            this.toggleEditModal(false);
                        }}
                        className="btn-icon btn-round"
                        color="warning"
                        size="sm"
                    >
                        <i className="fa fa-edit" />
                    </Button>{" "}
                    {/* use this button to remove the data row */}
                    <Button
                        disabled={true}
                        onClick={() => {
                            var data = this.state.data;
                            this.deleteCourse(prop.sk);
                            data.find((o, i) => {
                                if (o.id === key) {
                                    // here you should add some custom code so you can delete the data
                                    // from this component and from your server as well
                                    data.splice(i, 1);
                                    console.log(data);
                                    return true;
                                }
                                return false;
                            });
                            this.setState({ data: data });
                        }}
                        className="btn-icon btn-round"
                        color="danger"
                        size="sm"
                    >
                        <i className="fa fa-times" />
                    </Button>{" "}
                </div>
            ),
        };
    }

    async componentDidMount() {
        console.log("ComponentDidMount");

        const user = await Auth.currentAuthenticatedUser();
        const jwtToken = user.signInUserSession.idToken.jwtToken;

        console.log(jwtToken);

        let apiName = config.api.API_NAME;
        let path = '/bookings';
        let myInit = { // OPTIONAL
            headers: {
                Authorization: "Bearer " + jwtToken
            }, // OPTIONAL
            response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {  // OPTIONAL
                // id: 123
            }
        }
        API.get(apiName, path, myInit).then(response => {
            console.log("Respone : " + JSON.stringify(response));
            this.setState({
                data: response.map((prop, key) => {
                    return this.createTableRow(prop, key);
                })

            });
        }).catch(error => {
            console.log(error);
        });


    }

    render() {
        return (
            <>
                <EditCertificateModal updateTable={this.updateTable} isOpen={this.state.editModal} toggle={this.toggleEditModal} newCertificate={this.state.newCertificate} booking={this.state.booking} certificate={this.state.certificate} />
                <PanelHeader
                    size="sm"
                    content={
                        <div className="header text-center">
                            {/* <h2 className="title">Courses</h2> */}
                            {/* <p className="category">
                A powerful react plugin handcrafted by our friends from{" "}
                <a
                  href="https://react-table.js.org/#/story/readme"
                  target="_blank"
                >
                  react-table
                </a>
                . It is a highly flexible tool, based upon the foundations of
                progressive enhancement on which you can add advanced
                interaction controls. Please check out their{" "}
                <a
                  href="https://react-table.js.org/#/story/readme"
                  target="_blank"
                >
                  full documentation.
                </a>
              </p> */}
                        </div>
                    }
                />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Bookings
                                        {/* <Button color="info" className="btn-round float-right" size="sm"
                                            onClick={() => this.toggleEditModal(true)}
                                        >
                                            <i className="now-ui-icons ui-2_favourite-28" /> New Booking
                                        </Button> */}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.state.data}
                                        columns={[
                                            {
                                                Header: "Name",
                                                accessor: "name",
                                            },
                                            {
                                                Header: "Email",
                                                accessor: "email",
                                            },
                                            {
                                                Header: "Phone",
                                                accessor: "phone",
                                            },
                                            {
                                                Header: "Course Title",
                                                accessor: "courseTitle",
                                            },
                                            {
                                                Header: "Course Date",
                                                accessor: "courseDate",
                                            },
                                            {
                                                Header: "Booking Date",
                                                accessor: "dateCreated",
                                            },
                                            {
                                                Header: "Status",
                                                accessor: "status",
                                            },
                                            {
                                                Header: "Actions",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                            },
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default ListBookings;
