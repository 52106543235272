/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Auth, API } from 'aws-amplify';
import config from "../../config";
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import * as Constants from '../../util/Constants.jsx'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import LoadingOverlay from 'react-loading-overlay';
import SweetAlert from "react-bootstrap-sweetalert";





// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Form,
  UncontrolledTooltip,
  CardTitle,
  Input,
  Label
} from "reactstrap";

// core components
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import { listData } from "util/ComponentUtil";


class CompanySettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      valid: false,
      settings: {
        alertEmailAddresses: "",
        sendAlertEmails: false
      }
    };

    this.valueChange = this.valueChange.bind(this);
    this.save = this.save.bind(this);

  }



  hideAlert() {
    this.setState({
      alert: null
    });
  }



  downloadAppReport = async (event, reportPath, fileName) => {
    event.preventDefault();

    console.log("downloadAppReport");
    let state = Object.assign({}, this.state);
    state.loading = true;
    this.setState(state);

    const user = await Auth.currentAuthenticatedUser();
    let apiName = config.api.API_NAME;
    let path = reportPath;

    let jwtToken = user.signInUserSession.idToken.jwtToken;
    let myInit = { // OPTIONAL
      headers: {
        Authorization: "Bearer " + jwtToken
      }, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      // queryStringParameters: {  // OPTIONAL
      //   fromDate: this.state.fromDate,
      //   toDate: this.state.toDate,
      //   season: localStorage.getItem("selectedSeason")
      // }
    }
    API.get(apiName, path, myInit).then(response => {
      // console.log("Respone : " + JSON.stringify(response));

      const ws = XLSX.utils.json_to_sheet(response);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: Constants.xlxsFileType });
      FileSaver.saveAs(data, fileName + "_" + moment().format("YYYY_MM_DD_HH_mm") + Constants.xlxsFileExtension);
      this.setState({ loading: false });

    }).catch(error => {
      console.log(error.response ? error.response : error);
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title={"Download Failed"}
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="info"
          >
            {error.response ? error.response : error.message ? error.message : JSON.stringify(error)}
          </SweetAlert>
        ),
        loading: false
      });

    });
  }

  async save(e) {
    console.log("Save!!");
    const user = await Auth.currentAuthenticatedUser();
    const jwtToken = user.signInUserSession.idToken.jwtToken;

    let apiName = config.api.API_NAME;
    let path = '/settings';
    let myInit = { // OPTIONAL
      headers: {
        Authorization: "Bearer " + jwtToken
      }, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: this.state.settings
    }
    // console.log("myInit = " + JSON.stringify(myInit));
    API.post(apiName, path, myInit).then(response => {
      console.log("Respone : " + JSON.stringify(response));
      this.setState({
        valid: false,
        alert: (
          <SweetAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title={"Saved successfully"}
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="info"
          >
            Settings Saved!
          </SweetAlert>
        ),

      });

    }).catch(error => {
      console.log(error);
    });
  }

  valueChange(e) {
    let settings = Object.assign({}, this.state.settings);
    settings[e.target.name] = e.target.value;
    this.setState({
      settings: settings
    }, this.checkValid(settings));
  }


  checkValid(settings) {
    let { alertEmailAddresses, sendAlertEmails } = settings;

    let valid = true;
    if (sendAlertEmails === true && !this.checkEmailAddresses(alertEmailAddresses)) {
      valid = false;
    } else if(sendAlertEmails === false){
      valid = true;
    }
    this.setState({ valid: valid });
  }

  checkEmailAddresses(emailAddresses) {
    var emails = emailAddresses.replace(/\s/g, '').split(",");
    var valid = true;
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    for (var i = 0; i < emails.length; i++) {
      if (emails[i] == "" || !regex.test(emails[i])) {
        valid = false;
      }
    }
    return valid;
  }



  async componentDidMount() {
    console.log("ComponentDidMount settings" );

    let path = "/settings";
    const response = await listData(path);
    if (response && response.length > 0) {
      this.setState({
        settings: response[0]
      });
    }
  }

  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          {this.state.alert}
          <LoadingOverlay
            active={this.state.loading}
            spinner
            text='Loading please wait...'
          >

            <Card className="card-tasks">
              <CardHeader>
                <h5 className="card-category">Company Settings</h5>
                <CardTitle tag="h4">Settings</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          id="accept"
                          checked={this.state.settings.sendAlertEmails}
                          value={this.state.settings.sendAlertEmails}
                          onChange={() => {
                            console.log("Checked : " +this.state.settings.sendAlertEmails);
                            let settings = Object.assign({}, this.state.settings);
                            settings.sendAlertEmails = !settings.sendAlertEmails;
                            this.setState({ settings: settings })
                            this.checkValid(settings);
                          }}
                        />
                        <span className="form-check-sign" />
                        <div>
                          Send Alert Emails?
                        </div>
                      </Label>
                    </FormGroup>
                  </Row>
                  {this.state.settings.sendAlertEmails === true &&
                    <Row>
                      <Label sm={2}>Alert Email Addresses</Label>
                      <Col xs={12} sm={7}>
                        <FormGroup>
                          <Input
                            placeholder="example@gmail.com,example2@gmail.com"
                            value={this.state.settings.alertEmailAddresses}
                            onChange={this.valueChange}
                            name="alertEmailAddresses"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  }
                  <Row>
                    <Col xs={12} sm={7}>
                      <Button disabled={!this.state.valid} color="success"
                        onClick={this.save} >Save</Button>
                    </Col>
                  </Row>

                </Form>
              </CardBody>

              {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="now-ui-icons loader_refresh spin" /> Updated 3
                    minutes ago
                  </div>
                </CardFooter> */}
            </Card>
          </LoadingOverlay>
        </div>
      </>
    );
  }
}

export default withRouter(CompanySettings);
