/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { Component } from "react";

import { Auth, API } from 'aws-amplify';
import config from "../../config";



class EditModalCommon extends Component {

    constructor(props, objState, initRecord) {
        super(props);
        this.state = {
            ...objState,
            type: {
                required: "",
                email: "",
                number: "",
                url: "",
                source: "",
                destionation: "",
                requiredState: "",
                emailState: "",
                numberState: "",
                urlState: "",
                equalState: "",
            },
            initRecord: initRecord
        };

        this.valueChange = this.valueChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.save = this.save.bind(this);
        this.setDocumentState = this.setDocumentState.bind(this);
    }

    async save(e) {
        console.log("Save Clicked");

        const user = await Auth.currentAuthenticatedUser();
        const jwtToken = user.signInUserSession.idToken.jwtToken;

        let apiName = config.api.API_NAME;
        let path = '/' + this.state.domainObject + "s";
        let myInit = { // OPTIONAL
            headers: {
                Authorization: "Bearer " + jwtToken
            }, // OPTIONAL
            response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            body: this.state[this.state.domainObject]
        }
        // console.log("myInit = " + JSON.stringify(myInit));
        API.post(apiName, path, myInit).then(response => {
            console.log("Respone : " + JSON.stringify(response));
            this.props.updateTable(response);
            this.props.toggle();
            console.log("this.state.addCertificates : "+this.state.addCertificates);
            if( this.state.addCertificates === true && this.props.openCertificates) {
                this.props.openCertificates(response.id);
                this.setState({addCertificates : false});
            }
            // this.setState({
            //     [this.state.domainObject]: this.state.initRecord
            // });
            if (this.child) {
                this.child.save(this.state.domainObject, response.id);
            }

        }).catch(error => {
            console.log(error);
        });

    }

    typeRequired(e) {
        var type = this.state.type;
        type["required"] = e.target.value;
        if (type["required"].length > 0) {
            type["requiredState"] = "has-success";
        } else {
            type["requiredState"] = "has-danger";
        }
        this.setState({ type });
        // console.log(JSON.stringify(type));
    }


    valueChange(e) {
        this.valueChangeBase(e.target.name, e.target.value);
    }

    handleDateChange(date, name) {
        this.valueChangeBase(name, date.valueOf());
    }

    setDocumentState(name, documents) {
        this.valueChangeBase(name, documents);
    }

    valueChangeBase(name, value) {
        let state = Object.assign({}, this.state);
        console.log("state : " + JSON.stringify(state));
        let domainObject = state[this.state.domainObject];
        domainObject[name] = value;
        state.valid = this.checkValid(domainObject);
        this.setState(state);
    }


    componentDidUpdate(prevProps) {
        const { record, newRecord } = this.props;
        console.log("New record: " + newRecord);
        if ((record && !prevProps.record) || (record && prevProps.record && prevProps.record.id !== record.id) || (record && prevProps.record && !prevProps.record.id && record.id)) {
            this.setState({
                [this.state.domainObject]: this.props.newRecord === false ? record : Object.assign({}, this.state.initRecord)
            });
        } else if (newRecord === true && prevProps.newRecord !== newRecord) {

            this.setState({
                [this.state.domainObject]: JSON.parse(JSON.stringify(this.state.initRecord))
            });
        }
    }


}

export default EditModalCommon;
