/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import { Auth, API } from 'aws-amplify';
import config from "../../config";
import SweetAlert from "react-bootstrap-sweetalert";
import { listData } from "../../util/ListPageUtil";
import { hideAlert } from "../../util/ComponentUtil"


class ListCommon extends Component {
    constructor(props, objState) {
        super(props);
        this.state = {
            ...objState,
            editModal: false,
            data: [],
            alert: null

        };
        this.updateTable = this.updateTable.bind(this);
    }

    toggleEditModal = (newRecord, record) => {
        console.log("toggleEditModal : "+record +" open "+this.state.editModal);
        this.setState({
            editModal: !this.state.editModal,
            newRecord: newRecord,
            record: record
        });
    };

    updateTable(record) {
        let data = Object.assign([], this.state.data);
        let response = Object.assign([], this.state.response);
        let origLen = data.length;
        data = data.filter((o) => o.id !== record.id);

        if (data.length === origLen) {
            //this is not an existing course
            response.push(record);
            this.setState({
                response: response
            });
        }

        data.push(this.createTableRow(record, data.length + 1));

        this.setState({
            data: data
        });
    }

    async delete(id) {
        console.log("delete: " + id);

        const user = await Auth.currentAuthenticatedUser();
        const jwtToken = user.signInUserSession.idToken.jwtToken;

        // console.log(jwtToken);

        let apiName = config.api.API_NAME;
        let path = this.state.path + "/" + encodeURIComponent(id);
        let myInit = { // OPTIONAL
            headers: {
                Authorization: "Bearer " + jwtToken
            }, // OPTIONAL
            response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            // queryStringParameters: {  // OPTIONAL
            //     id: certificate.sk
            // }
        }
        API.del(apiName, path, myInit).then(response => {
            console.log("Respone : " + JSON.stringify(response));



        }).catch(error => {
            console.log(error);
        });
    }

    deleteRecord(recordSk, recordId) {

        console.log("Delete Record: " + recordSk);

        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => {
                        let data = Object.assign([], this.state.data);
                        this.delete(recordSk);
                        data = data.filter((o) => o.id !== recordId);
                        this.setState({ data: data });
                        hideAlert(this.setState.bind(this));
                    }}
                    onCancel={() => hideAlert(this.setState.bind(this))}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, delete!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Delete Record
                </SweetAlert>
            )
        });
    }



    async componentDidMount() {
        console.log("ComponentDidMount " + this.state.domainObject);

        let path = "/" + this.state.domainObject + "s";
        const response = await listData(path);
        let data = response.map((prop, key) => this.createTableRow(prop, key));
        this.setState({
            data: data,
            response: response,
            path: path
        });
    }



}

export default ListCommon;
