    /**
     * Find the layout for the appropiate user
     * 
     */
    export function findUserLayout(user) {
        return "/admin";
        // let admin = user.attributes["custom:userType"].indexOf("ADMIN") !== -1;
        // //let applicant = user.attributes["custom:userType"].indexOf("APPLICANT") !== -1;
        // let emp = user.attributes["custom:userType"].indexOf("EMP") !== -1;
        // let rep = user.attributes["custom:userType"].indexOf("REP") !== -1 && user.attributes["custom:userType"].indexOf("APPLICANT") === -1;
        // let layout = admin ? "/admin" : rep ? "/rep" : emp ? "/emp" : "/appl";
        // console.log("Layout = "+layout);
        // return layout;
    }


    /**
     * Find if its an admin
     * 
     */
    export function isAdmin(user) {
        return user && user.attributes["custom:userType"].indexOf("ADMIN") !== -1 ? true : false;
    }

    /**
     * Find if its an rep
     * 
     */
    export function isRep(user) {
        return user.attributes["custom:userType"].indexOf("REP") !== -1 && user.attributes["custom:userType"].indexOf("APPLICANT") === -1;
    }

     /**
     * Find if its an applicant
     * 
     */
    export function isApplicant(user) {
        return user.attributes["custom:userType"].indexOf("APPLICANT") !== -1;
    }

    /**
     * Find if its an employer
     * 
     */
    export function isEmployer(user) {
        return user && user.attributes["custom:userType"].indexOf("EMP") !== -1;
    }



    export default findUserLayout;

    