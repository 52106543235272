/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from "reactstrap";
import { Auth } from "aws-amplify";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
// import logo from "assets/img/awe_logo_sm.png";

import bgImage from "assets/img/bg14.jpg";

import findUserLayout from "../../util/UserUtil";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword2: "",
      alert: null,
      newPassword: "",
      errors: {
        cognito: null,
        blankfield: false
      }
    };

    this.hideAlert = this.hideAlert.bind(this);
  }


  hideAlert() {
    this.setState({
      alert: null
    });
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  };

  autoCloseAlert() {
    
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Password Change Failed!"
          onConfirm={() => this.hideAlert()}
          showConfirm={false}
          
        >
          {this.state.errors.cognito.message}
        </SweetAlert>
      )
    });
    setTimeout(this.hideAlert, 3000);
  }

  handleSubmit = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    //const error = Validate(event, this.state);
    // if (error) {
    //   this.setState({
    //     errors: { ...this.state.errors, ...error }
    //   });
    // }


    
    // AWS Cognito integration here
    try {
      let user = await Auth.completeNewPassword(this.props.auth.user, this.state.newPassword);

      let email = user.email;

      user = await Auth.signIn(email, this.state.newPassword);
      user["email"] = email;
      user["shortEmail"] = email.substring(0, email.indexOf("@"));
      this.props.auth.setAuthStatus(true);
      this.props.auth.setUser(user);

      console.log("UserType : "+JSON.stringify(user.attributes));

      let layout = findUserLayout(user);
      this.props.history.push(layout);
    }catch(error) {
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }        
      });
      this.autoCloseAlert.bind(this);
      this.autoCloseAlert();
    }
  };


  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  


  



  componentDidMount() {
    document.body.classList.add("login-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("login-page");
  }



  render() {
    return (
      <>
        <div className="content">
          {this.state.alert}
          <div className="login-page">
            <Container>
              <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
                
                <Form>
                  <Card className="card-login card-plain">
                    <CardHeader>
                      {/* <div className="logo-container">
                        <img src={logo} alt="now-logo" />
                      </div> */}
                      <h6 style={{color: "white"}}>
                         Change Password
                      </h6>
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        className={
                          "no-border form-control-lg " } >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons text_caps-small" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          valid={this.state.newPassword && this.state.newPassword2 && this.state.newPassword2 !== "" && this.state.newPassword2 === this.state.newPassword}
                          type="password"
                          id="newPassword"
                          placeholder="New Password..."
                          value={this.state.newPassword}
                          onChange={this.onInputChange}
                        />
                        <FormFeedback>You will not be able to see this</FormFeedback>
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border form-control-lg " } >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons text_caps-small" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          valid={this.state.newPassword && this.state.newPassword2 && this.state.newPassword2 !== "" && this.state.newPassword2 === this.state.newPassword}
                          type="password"
                          id="newPassword2"
                          placeholder="Repeat New Password..."
                          value={this.state.newPassword2}
                          onChange={this.onInputChange}
                        />
                        <FormFeedback>You will not be able to see this</FormFeedback>
                      </InputGroup>
                    </CardBody>
                    <CardFooter>
                      <Button
                        block
                        color="primary"
                        size="lg"
                        onClick={this.handleSubmit}
                        className="mb-3 btn-round"
                        disabled={!this.state.newPassword || !this.state.newPassword2 || this.state.newPassword2 === "" || this.state.newPassword2 !== this.state.newPassword}
                      >
                        Change Password
                      </Button>
                      <div className="pull-left">
                        <h6>
                          <a href="#pablo" className="link footer-link">
                            Create Account
                          </a>
                        </h6>
                      </div>
                      <div className="pull-right">
                        <h6>
                          {/* <a href="#pablo" className="link footer-link">
                            Need Help?
                          </a> */}
                        </h6>
                      </div>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundImage: "url(" + bgImage + ")" }}
        />
      </>
    );
  }
}

export default ChangePassword;
