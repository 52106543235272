/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    ButtonGroup,
    Row,
    Col,
    Label,
    Form,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    FormGroup,
    Input
} from "reactstrap";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// react plugin used to create datetimepicker
import Datetime from "react-datetime";
import moment from 'moment';







var locationOptions = [
    { value: "Dublin", label: "Dublin" },
    { value: "Clare", label: "Clare" },
    { value: "Galway", label: "Galway" },
    { value: "Meath", label: "Meath" },
    { value: "Wicklow", label: "Wicklow" }
];

class CourseDTModal extends Component {
    constructor(props) {
        super(props);
        let noPos = [];
        for (let i = 1; i <= 100; i++) {
            noPos.push({ value: i, label: i });
        }
        this.state = {
            noPos: noPos,
            scheduleDate: "",
            scheduleTime: "",
            noParticipants: "",
            dateTimes: [
                1232456,
                2324542,
                3443434,
                1232456,
                2324542,
                3443434,
                1232456,
                2324542,
                3443434,
                1232456,
                2324542,
                3443434
            ],
            register: {
                emailState: "",
                passwordState: "",
                confirmState: "",
                fullNameState: "",
                email: "",
                password: "",
                confirm: "",
                fullName: "",
            },
            type: {
                required: "",
                email: "",
                number: "",
                url: "",
                source: "",
                destionation: "",
                requiredState: "",
                emailState: "",
                numberState: "",
                urlState: "",
                equalState: "",
            }
        };

        this.noParticipantsChange = this.noParticipantsChange.bind(this);
    }

    registerEmail(e) {
        var register = this.state.register;
        register["email"] = e.target.value;
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(e.target.value)) {
            register["emailState"] = "has-success";
        } else {
            register["emailState"] = "has-danger";
        }
        this.setState({ register });
    }

    registerSubmit(e) {
        var register = this.state.register;
        if (register["emailState"] !== "has-success")
            register["emailState"] = "has-danger";
        if (register["passwordState"] !== "has-success")
            register["passwordState"] = "has-danger";
        if (register["confirmState"] !== "has-success")
            register["confirmState"] = "has-danger";
        this.setState({ register });
    }

    typeRequired(e) {
        var type = this.state.type;
        type["required"] = e.target.value;
        if (type["required"].length > 0) {
            type["requiredState"] = "has-success";
        } else {
            type["requiredState"] = "has-danger";
        }
        this.setState({ type });
    }

    handleDateChange(date) {
        // let dateStr = date instanceof moment ? date.valueOf() : "";
        let state = JSON.parse(JSON.stringify(this.state));
        state.scheduleDate = date.format("DD/MM/YYYY");
        this.setState(state);
    }

    handleTimeChange(date) {
        // console.log(date.format());
        // let dateStr = date instanceof moment ? date.valueOf() : "";
        let state = JSON.parse(JSON.stringify(this.state));
        state.scheduleTime = date.format("HH:mm");
        this.setState(state);
    }

    noParticipantsChange(e) {
        let state = JSON.parse(JSON.stringify(this.state));
        state.noParticipants = e.target.value.replace(/\D/, '');
        this.setState(state);
    }    



    render() {
        return (
            <Modal style={{ maxWidth: '1000px', width: '95%', margin: '10px auto' }}
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}

            >
                {/* <ModalHeader
                    className="justify-content-center uppercase title"
                    toggle={this.props.toggle}
                    tag="h4"
                >
                    {this.props.newCourse === true ? "New Course" : "Edit Course"}
                </ModalHeader> */}
                <ModalBody>
                    <div className="content">
                        <Row>
                            <Col xs={12} md={12}>
                                <Form className="form-horizontal">
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h4">Select date and time</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Label sm={2}>Number of Participants</Label>
                                                <Col xs={12} sm={7}>
                                                    <FormGroup className={this.state.type.requiredState}>
                                                        <Input
                                                            value={this.state.noParticipants}
                                                            onChange={this.noParticipantsChange}
                                                            name="noParticipants"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Label sm={3} className="label-on-right">
                                                    <code>*</code>
                                                </Label>
                                            </Row>
                                            <Row>
                                                <Label sm={2}>Select Date & Time</Label>
                                                <Col xs={12} sm={3}>
                                                    <FormGroup className={this.state.type.requiredState}>
                                                        <Datetime
                                                            // value={this.state.scheduleDate}
                                                            utc={true}
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            inputProps={{ placeholder: "Select Date" }}
                                                            onChange={value => this.handleDateChange(value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} sm={3}>
                                                    <FormGroup className={this.state.type.requiredState}>
                                                        <Datetime
                                                            // value={this.state.scheduleTime}
                                                            utc={true}
                                                            closeOnSelect={true}
                                                            timeFormat="HH:mm"
                                                            dateFormat={false}
                                                            inputProps={{ placeholder: "Select Time" }}
                                                            onChange={value => this.handleTimeChange(value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Label sm={3} className="label-on-right">
                                                    <code>*</code>
                                                </Label>

                                            </Row>


                                        </CardBody>
                                        <CardFooter className="text-left">


                                        </CardFooter>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="success"
                        disabled={!this.state.scheduleDate || !this.state.scheduleTime || !this.state.noParticipants}
                        onClick={() => {
                            this.props.addSchedule(this.state.scheduleDate, this.state.scheduleTime, this.state.noParticipants);
                            this.setState({
                                scheduleDate: "",
                                scheduleTime: ""
                            });
                        }}
                    >Select</Button>
                    <Button
                        color="danger"
                        onClick={this.props.toggle}
                    >
                        Close
                          </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default CourseDTModal;
